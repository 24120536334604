.dropl {
    .dropl-item.active ~ .dropl-off {
        display: none;
    }
}

.drop-item-spin.active ~ .drop-item-spin-off {
    display: none;
}

.opacity-10{
    opacity: .1 !important;
}

.table > :not(caption) > * > * {
   padding: 0.1rem 0.3rem !important;
}
