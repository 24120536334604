.dropl {
    .dropl-item.active ~ .dropl-off {
        display: none;
    }
}

.drop-item-spin.active ~ .drop-item-spin-off {
    display: none;
}

.opacity-10{
    opacity: .1 !important;
}

.table > :not(caption) > * > * {
   padding: 0.1rem 0.3rem !important;
}

.m_active{
    background :#f1f1f1;
    background: linear-gradient(to right, #eeeeee 0%,#ffffff 100%);
}
.block-content.block-content-full{
        padding-top: 0.25rem !important;
         padding-bottom: 0.25rem !important;
}