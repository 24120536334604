@charset "UTF-8";
/* cyrillic-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokSdh18Smxg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokSdo18Smxg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokSdg18Smxg.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokSdv18Smxg.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokSdj18Smxg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokSdi18Smxg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokSds18Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkidh18Smxg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkido18Smxg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkidg18Smxg.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkidv18Smxg.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkidj18Smxg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkidi18Smxg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkids18Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7qsDJT9g.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7jsDJT9g.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7rsDJT9g.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7ksDJT9g.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7osDJT9g.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7psDJT9g.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7nsDI.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lCdh18Smxg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lCdo18Smxg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lCdg18Smxg.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lCdv18Smxg.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lCdj18Smxg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lCdi18Smxg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lCds18Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclSdh18Smxg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclSdo18Smxg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclSdg18Smxg.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclSdv18Smxg.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclSdj18Smxg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclSdi18Smxg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclSds18Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklydh18Smxg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklydo18Smxg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklydg18Smxg.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklydv18Smxg.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklydj18Smxg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklydi18Smxg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklyds18Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_wmhduz8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_wkxduz8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_wmxduz8A.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_wlBduz8A.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_wmBduz8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_wmRduz8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_wlxdu.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmhduz8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwkxduz8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmxduz8A.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlBduz8A.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmBduz8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmRduz8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdu.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lqDY.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lqDY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNK7lqDY.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qO67lqDY.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qN67lqDY.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lqDY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmhduz8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwkxduz8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmxduz8A.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlBduz8A.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmBduz8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmRduz8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdu.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmhduz8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwkxduz8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmxduz8A.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlBduz8A.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmBduz8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmRduz8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdu.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwmhduz8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwkxduz8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwmxduz8A.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwlBduz8A.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwmBduz8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwmRduz8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwlxdu.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #f4623a;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #3c90df;
  --bs-warning: #e69f17;
  --bs-danger: #f1373b;
  --bs-light: #f0f3f8;
  --bs-dark: #343a40;
  --bs-primary-rgb: 244, 98, 58;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 60, 144, 223;
  --bs-warning-rgb: 230, 159, 23;
  --bs-danger-rgb: 241, 55, 59;
  --bs-light-rgb: 240, 243, 248;
  --bs-dark-rgb: 52, 58, 64;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 52, 58, 64;
  --bs-body-bg-rgb: 237, 240, 247;
  --bs-font-sans-serif: "Source sans pro", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-root-font-size: 16px;
  --bs-body-font-family: Source sans pro, Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #343a40;
  --bs-body-bg: #edf0f7;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--bs-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.1;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1.375rem;
  font-weight: 600;
  line-height: 1.25;
}

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.875rem;
  }
}

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.75rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #f4623a;
  text-decoration: none;
}
a:hover {
  color: #bd310b;
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  font-weight: 600;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #edf0f7;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.875rem);
  padding-left: var(--bs-gutter-x, 0.875rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.75rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 4.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 4.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 6rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 6rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 9rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 9rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 12rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 12rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 18rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 18rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 4.5rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 4.5rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 6rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 6rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 9rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 9rem;
  }

  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 12rem;
  }

  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 12rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 18rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 18rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 4.5rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 4.5rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 6rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 6rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 9rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 9rem;
  }

  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 12rem;
  }

  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 12rem;
  }

  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 18rem;
  }

  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 18rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 4.5rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 4.5rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 6rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 6rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 9rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 9rem;
  }

  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 12rem;
  }

  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 12rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 18rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 18rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 6rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 6rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 9rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 9rem;
  }

  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 12rem;
  }

  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 12rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 18rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 18rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 6rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 6rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 9rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 9rem;
  }

  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 12rem;
  }

  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 12rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 18rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 18rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #343a40;
  --bs-table-striped-bg: #f2f4f9;
  --bs-table-active-color: #343a40;
  --bs-table-active-bg: #edf0f7;
  --bs-table-hover-color: #343a40;
  --bs-table-hover-bg: #e8ecf5;
  width: 100%;
  margin-bottom: 1rem;
  color: #343a40;
  vertical-align: top;
  border-color: #dfe4f1;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid #dfe4f1;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #fde0d8;
  --bs-table-striped-bg: #f0d5cd;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4cac2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eacfc8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e4cac2;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #d8e9f9;
  --bs-table-striped-bg: #cddded;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2d2e0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c8d8e6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c2d2e0;
}

.table-warning {
  --bs-table-bg: #faecd1;
  --bs-table-striped-bg: #eee0c7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1d4bc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7dac1;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e1d4bc;
}

.table-danger {
  --bs-table-bg: #fcd7d8;
  --bs-table-striped-bg: #efcccd;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e3c2c2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e9c7c8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e3c2c2;
}

.table-light {
  --bs-table-bg: #f0f3f8;
  --bs-table-striped-bg: #e4e7ec;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d8dbdf;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dee1e5;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d8dbdf;
}

.table-dark {
  --bs-table-bg: #343a40;
  --bs-table-striped-bg: #3e444a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #484e53;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #43494e;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #484e53;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.375rem;
  font-weight: 500;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.375rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control, .select2-container--default .select2-selection--multiple, .select2-container--default .select2-selection--single {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d8ea;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control, .select2-container--default .select2-selection--multiple, .select2-container--default .select2-selection--single {
    transition: none;
  }
}
.form-control[type=file], .select2-container--default [type=file].select2-selection--multiple, .select2-container--default [type=file].select2-selection--single {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]), .select2-container--default [type=file].select2-selection--multiple:not(:disabled):not([readonly]), .select2-container--default [type=file].select2-selection--single:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus, .select2-container--default .select2-selection--multiple:focus, .select2-container--default .select2-selection--single:focus {
  color: #343a40;
  background-color: #fff;
  border-color: #fab19d;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.form-control::-webkit-date-and-time-value, .select2-container--default .select2-selection--multiple::-webkit-date-and-time-value, .select2-container--default .select2-selection--single::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder, .select2-container--default .select2-selection--multiple::placeholder, .select2-container--default .select2-selection--single::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .select2-container--default .select2-selection--multiple:disabled, .select2-container--default .select2-selection--single:disabled, .form-control[readonly], .select2-container--default [readonly].select2-selection--multiple, .select2-container--default [readonly].select2-selection--single {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button, .select2-container--default .select2-selection--multiple::file-selector-button, .select2-container--default .select2-selection--single::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #343a40;
  background-color: #edf0f7;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button, .select2-container--default .select2-selection--multiple::file-selector-button, .select2-container--default .select2-selection--single::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button, .select2-container--default .select2-selection--multiple:hover:not(:disabled):not([readonly])::file-selector-button, .select2-container--default .select2-selection--single:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e1e4eb;
}
.form-control::-webkit-file-upload-button, .select2-container--default .select2-selection--multiple::-webkit-file-upload-button, .select2-container--default .select2-selection--single::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #343a40;
  background-color: #edf0f7;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button, .select2-container--default .select2-selection--multiple::-webkit-file-upload-button, .select2-container--default .select2-selection--single::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button, .select2-container--default .select2-selection--multiple:hover:not(:disabled):not([readonly])::-webkit-file-upload-button, .select2-container--default .select2-selection--single:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e1e4eb;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #343a40;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control, .select2-container--default textarea.select2-selection--multiple, .select2-container--default textarea.select2-selection--single {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #d1d8ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #fab19d;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #343a40;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #cad3e7;
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #fab19d;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.form-check-input:checked {
  background-color: #f4623a;
  border-color: #f4623a;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #f4623a;
  border-color: #f4623a;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 3em;
}
.form-switch .form-check-input {
  width: 2.5em;
  margin-left: -3em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23cad3e7'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2.5em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fab19d'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #edf0f7, 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #edf0f7, 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #f4623a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #fcd0c4;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #f4623a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #fcd0c4;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control, .select2-container--default .form-floating > .select2-selection--multiple, .select2-container--default .form-floating > .select2-selection--single,
.form-floating > .form-select {
  height: 3.25rem;
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0.8rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control, .select2-container--default .form-floating > .select2-selection--multiple, .select2-container--default .form-floating > .select2-selection--single {
  padding: 0.8rem 0.75rem;
}
.form-floating > .form-control::placeholder, .select2-container--default .form-floating > .select2-selection--multiple::placeholder, .select2-container--default .form-floating > .select2-selection--single::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .select2-container--default .form-floating > .select2-selection--multiple:focus, .select2-container--default .form-floating > .select2-selection--single:focus, .form-floating > .form-control:not(:placeholder-shown), .select2-container--default .form-floating > .select2-selection--multiple:not(:placeholder-shown), .select2-container--default .form-floating > .select2-selection--single:not(:placeholder-shown) {
  padding-top: 1.5rem;
  padding-bottom: 0.25rem;
}
.form-floating > .form-control:-webkit-autofill, .select2-container--default .form-floating > .select2-selection--multiple:-webkit-autofill, .select2-container--default .form-floating > .select2-selection--single:-webkit-autofill {
  padding-top: 1.5rem;
  padding-bottom: 0.25rem;
}
.form-floating > .form-select {
  padding-top: 1.5rem;
  padding-bottom: 0.25rem;
}
.form-floating > .form-control:focus ~ label, .select2-container--default .form-floating > .select2-selection--multiple:focus ~ label, .select2-container--default .form-floating > .select2-selection--single:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.select2-container--default .form-floating > .select2-selection--multiple:not(:placeholder-shown) ~ label,
.select2-container--default .form-floating > .select2-selection--single:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label, .select2-container--default .form-floating > .select2-selection--multiple:-webkit-autofill ~ label, .select2-container--default .form-floating > .select2-selection--single:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control, .select2-container--default .input-group > .select2-selection--multiple, .select2-container--default .input-group > .select2-selection--single,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus, .select2-container--default .input-group > .select2-selection--multiple:focus, .select2-container--default .input-group > .select2-selection--single:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  text-align: center;
  white-space: nowrap;
  background-color: #edf0f7;
  border: 1px solid #d1d8ea;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control, .select2-container--default .input-group-lg > .select2-selection--multiple, .select2-container--default .input-group-lg > .select2-selection--single,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control, .select2-container--default .input-group-sm > .select2-selection--multiple, .select2-container--default .input-group-sm > .select2-selection--single,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.875rem;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .was-validated .select2-container--default .select2-selection--multiple:valid, .select2-container--default .was-validated .select2-selection--multiple:valid, .was-validated .select2-container--default .select2-selection--single:valid, .select2-container--default .was-validated .select2-selection--single:valid, .form-control.is-valid, .select2-container--default .is-valid.select2-selection--multiple, .select2-container--default .is-valid.select2-selection--single {
  border-color: #198754;
}
.was-validated .form-control:valid:focus, .was-validated .select2-container--default .select2-selection--multiple:valid:focus, .select2-container--default .was-validated .select2-selection--multiple:valid:focus, .was-validated .select2-container--default .select2-selection--single:valid:focus, .select2-container--default .was-validated .select2-selection--single:valid:focus, .form-control.is-valid:focus, .select2-container--default .is-valid.select2-selection--multiple:focus, .select2-container--default .is-valid.select2-selection--single:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .was-validated .input-group .select2-container--default .select2-selection--multiple:valid, .select2-container--default .was-validated .input-group .select2-selection--multiple:valid, .was-validated .input-group .select2-container--default .select2-selection--single:valid, .select2-container--default .was-validated .input-group .select2-selection--single:valid, .input-group .form-control.is-valid, .input-group .select2-container--default .is-valid.select2-selection--multiple, .select2-container--default .input-group .is-valid.select2-selection--multiple, .input-group .select2-container--default .is-valid.select2-selection--single, .select2-container--default .input-group .is-valid.select2-selection--single,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .was-validated .input-group .select2-container--default .select2-selection--multiple:valid:focus, .select2-container--default .was-validated .input-group .select2-selection--multiple:valid:focus, .was-validated .input-group .select2-container--default .select2-selection--single:valid:focus, .select2-container--default .was-validated .input-group .select2-selection--single:valid:focus, .input-group .form-control.is-valid:focus, .input-group .select2-container--default .is-valid.select2-selection--multiple:focus, .select2-container--default .input-group .is-valid.select2-selection--multiple:focus, .input-group .select2-container--default .is-valid.select2-selection--single:focus, .select2-container--default .input-group .is-valid.select2-selection--single:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.875rem;
  color: #f1373b;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(241, 55, 59, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .was-validated .select2-container--default .select2-selection--multiple:invalid, .select2-container--default .was-validated .select2-selection--multiple:invalid, .was-validated .select2-container--default .select2-selection--single:invalid, .select2-container--default .was-validated .select2-selection--single:invalid, .form-control.is-invalid, .select2-container--default .is-invalid.select2-selection--multiple, .select2-container--default .is-invalid.select2-selection--single {
  border-color: #f1373b;
}
.was-validated .form-control:invalid:focus, .was-validated .select2-container--default .select2-selection--multiple:invalid:focus, .select2-container--default .was-validated .select2-selection--multiple:invalid:focus, .was-validated .select2-container--default .select2-selection--single:invalid:focus, .select2-container--default .was-validated .select2-selection--single:invalid:focus, .form-control.is-invalid:focus, .select2-container--default .is-invalid.select2-selection--multiple:focus, .select2-container--default .is-invalid.select2-selection--single:focus {
  border-color: #f1373b;
  box-shadow: 0 0 0 0.25rem rgba(241, 55, 59, 0.25);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #f1373b;
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #f1373b;
  box-shadow: 0 0 0 0.25rem rgba(241, 55, 59, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #f1373b;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #f1373b;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 55, 59, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f1373b;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .was-validated .input-group .select2-container--default .select2-selection--multiple:invalid, .select2-container--default .was-validated .input-group .select2-selection--multiple:invalid, .was-validated .input-group .select2-container--default .select2-selection--single:invalid, .select2-container--default .was-validated .input-group .select2-selection--single:invalid, .input-group .form-control.is-invalid, .input-group .select2-container--default .is-invalid.select2-selection--multiple, .select2-container--default .input-group .is-invalid.select2-selection--multiple, .input-group .select2-container--default .is-invalid.select2-selection--single, .select2-container--default .input-group .is-invalid.select2-selection--single,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .was-validated .input-group .select2-container--default .select2-selection--multiple:invalid:focus, .select2-container--default .was-validated .input-group .select2-selection--multiple:invalid:focus, .was-validated .input-group .select2-container--default .select2-selection--single:invalid:focus, .select2-container--default .was-validated .input-group .select2-selection--single:invalid:focus, .input-group .form-control.is-invalid:focus, .input-group .select2-container--default .is-invalid.select2-selection--multiple:focus, .select2-container--default .input-group .is-invalid.select2-selection--multiple:focus, .input-group .select2-container--default .is-invalid.select2-selection--single:focus, .select2-container--default .input-group .is-invalid.select2-selection--single:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  color: #343a40;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #343a40;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #f4623a;
  border-color: #f4623a;
}
.btn-primary:hover {
  color: #fff;
  background-color: #c34e2e;
  border-color: #b74a2c;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #c34e2e;
  border-color: #b74a2c;
  box-shadow: 0 0 0 0.25rem rgba(246, 122, 88, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #c34e2e;
  border-color: #b74a2c;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(246, 122, 88, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #f4623a;
  border-color: #f4623a;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-success:hover {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #fff;
  background-color: #3c90df;
  border-color: #3c90df;
}
.btn-info:hover {
  color: #fff;
  background-color: #3073b2;
  border-color: #2d6ca7;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #fff;
  background-color: #3073b2;
  border-color: #2d6ca7;
  box-shadow: 0 0 0 0.25rem rgba(89, 161, 228, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #3073b2;
  border-color: #2d6ca7;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(89, 161, 228, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #3c90df;
  border-color: #3c90df;
}

.btn-warning {
  color: #000;
  background-color: #e69f17;
  border-color: #e69f17;
}
.btn-warning:hover {
  color: #000;
  background-color: #ebb245;
  border-color: #eaad3a;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ebb245;
  border-color: #eaad3a;
  box-shadow: 0 0 0 0.25rem rgba(196, 135, 20, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ebb245;
  border-color: #e9a92e;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(196, 135, 20, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #e69f17;
  border-color: #e69f17;
}

.btn-danger {
  color: #fff;
  background-color: #f1373b;
  border-color: #f1373b;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c12c2f;
  border-color: #b5292c;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #c12c2f;
  border-color: #b5292c;
  box-shadow: 0 0 0 0.25rem rgba(243, 85, 88, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c12c2f;
  border-color: #b5292c;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(243, 85, 88, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #f1373b;
  border-color: #f1373b;
}

.btn-light {
  color: #000;
  background-color: #f0f3f8;
  border-color: #f0f3f8;
}
.btn-light:hover {
  color: #000;
  background-color: #f3f5f9;
  border-color: #f2f5f9;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f3f5f9;
  border-color: #f2f5f9;
  box-shadow: 0 0 0 0.25rem rgba(204, 207, 211, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f3f5f9;
  border-color: #f2f4f9;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(204, 207, 211, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f0f3f8;
  border-color: #f0f3f8;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #2a2e33;
  border-color: #272c30;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #2a2e33;
  border-color: #272c30;
  box-shadow: 0 0 0 0.25rem rgba(82, 88, 93, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #2a2e33;
  border-color: #272c30;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(82, 88, 93, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-primary {
  color: #f4623a;
  border-color: #f4623a;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #f4623a;
  border-color: #f4623a;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #f4623a;
  border-color: #f4623a;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #f4623a;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #3c90df;
  border-color: #3c90df;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #3c90df;
  border-color: #3c90df;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 144, 223, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #3c90df;
  border-color: #3c90df;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 144, 223, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #3c90df;
  background-color: transparent;
}

.btn-outline-warning {
  color: #e69f17;
  border-color: #e69f17;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #e69f17;
  border-color: #e69f17;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(230, 159, 23, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #e69f17;
  border-color: #e69f17;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(230, 159, 23, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #e69f17;
  background-color: transparent;
}

.btn-outline-danger {
  color: #f1373b;
  border-color: #f1373b;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #f1373b;
  border-color: #f1373b;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 55, 59, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #f1373b;
  border-color: #f1373b;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 55, 59, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #f1373b;
  background-color: transparent;
}

.btn-outline-light {
  color: #f0f3f8;
  border-color: #f0f3f8;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f0f3f8;
  border-color: #f0f3f8;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 243, 248, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f0f3f8;
  border-color: #f0f3f8;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 243, 248, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f0f3f8;
  background-color: transparent;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(52, 58, 64, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #f4623a;
  text-decoration: none;
}
.btn-link:hover {
  color: #bd310b;
  text-decoration: none;
}
.btn-link:focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 12rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #343a40;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #dfe4f1;
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #dfe4f1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  clear: both;
  font-weight: 400;
  color: #343a40;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #343a40;
  background-color: #edf0f7;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #f4623a;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.375rem 0.75rem;
  color: #343a40;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: #dfe4f1;
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #f4623a;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: #dfe4f1;
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: #f4623a;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #bd310b;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dfe4f1;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #dfe4f1 #dfe4f1 #dfe4f1;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #343a40;
  background-color: #fff;
  border-color: #dfe4f1 #dfe4f1 #dfe4f1;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #f4623a;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #343a40;
  background-color: #edf0f7;
  border: 1px solid #edf0f7;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #343a40;
  background-color: #cad3e7;
  border-color: #cad3e7;
}
.page-link:focus {
  z-index: 3;
  color: #bd310b;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: none;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #f4623a;
  border-color: #f4623a;
}
.page-item.disabled .page-link {
  color: #adb5bd;
  pointer-events: none;
  background-color: #fff;
  border-color: #fff;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #923b23;
  background-color: #fde0d8;
  border-color: #fcd0c4;
}
.alert-primary .alert-link {
  color: #752f1c;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #245686;
  background-color: #d8e9f9;
  border-color: #c5def5;
}
.alert-info .alert-link {
  color: #1d456b;
}

.alert-warning {
  color: #8a5f0e;
  background-color: #faecd1;
  border-color: #f8e2b9;
}
.alert-warning .alert-link {
  color: #6e4c0b;
}

.alert-danger {
  color: #912123;
  background-color: #fcd7d8;
  border-color: #fbc3c4;
}
.alert-danger .alert-link {
  color: #741a1c;
}

.alert-light {
  color: #909295;
  background-color: #fcfdfe;
  border-color: #fbfbfd;
}
.alert-light .alert-link {
  color: #737577;
}

.alert-dark {
  color: #1f2326;
  background-color: #d6d8d9;
  border-color: #c2c4c6;
}
.alert-dark .alert-link {
  color: #191c1e;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1.25rem;
  }
}
.progress {
  display: flex;
  height: 1.25rem;
  overflow: hidden;
  font-size: 1rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #f4623a;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1.25rem 1.25rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #343a40;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #343a40;
  text-decoration: none;
  background-color: #f2f4f9;
}
.list-group-item-action:active {
  color: #343a40;
  background-color: #dfe4f1;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  color: #212529;
  background-color: #fff;
  border: 1px solid #dfe4f1;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #f4623a;
  border-color: #f4623a;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #923b23;
  background-color: #fde0d8;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #923b23;
  background-color: #e4cac2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #923b23;
  border-color: #923b23;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #245686;
  background-color: #d8e9f9;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #245686;
  background-color: #c2d2e0;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #245686;
  border-color: #245686;
}

.list-group-item-warning {
  color: #8a5f0e;
  background-color: #faecd1;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #8a5f0e;
  background-color: #e1d4bc;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #8a5f0e;
  border-color: #8a5f0e;
}

.list-group-item-danger {
  color: #912123;
  background-color: #fcd7d8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #912123;
  background-color: #e3c2c2;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #912123;
  border-color: #912123;
}

.list-group-item-light {
  color: #909295;
  background-color: #fcfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #909295;
  background-color: #e3e4e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #909295;
  border-color: #909295;
}

.list-group-item-dark {
  color: #1f2326;
  background-color: #d6d8d9;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1f2326;
  background-color: #c1c2c3;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1f2326;
  border-color: #1f2326;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.875rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.3;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dfe4f1;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dfe4f1;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Source sans pro", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Source sans pro", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dfe4f1;
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #dfe4f1;
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #dfe4f1;
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #dfe4f1;
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #fff;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #dfe4f1;
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.75rem 0.625rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #fff;
  border-bottom: 1px solid #dfe4f1;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.625rem 0.625rem;
  color: #343a40;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #f4623a;
}
.link-primary:hover, .link-primary:focus {
  color: #c34e2e;
}

.link-secondary {
  color: #6c757d;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #3c90df;
}
.link-info:hover, .link-info:focus {
  color: #3073b2;
}

.link-warning {
  color: #e69f17;
}
.link-warning:hover, .link-warning:focus {
  color: #ebb245;
}

.link-danger {
  color: #f1373b;
}
.link-danger:hover, .link-danger:focus {
  color: #c12c2f;
}

.link-light {
  color: #f0f3f8;
}
.link-light:hover, .link-light:focus {
  color: #f3f5f9;
}

.link-dark {
  color: #343a40;
}
.link-dark:hover, .link-dark:focus {
  color: #2a2e33;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm, .select2-container--default .select2-dropdown {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dfe4f1 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dfe4f1 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dfe4f1 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dfe4f1 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dfe4f1 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #f4623a !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #3c90df !important;
}

.border-warning {
  border-color: #e69f17 !important;
}

.border-danger {
  border-color: #f1373b !important;
}

.border-light {
  border-color: #f0f3f8 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 4.5rem !important;
}

.gap-7 {
  gap: 6rem !important;
}

.gap-8 {
  gap: 9rem !important;
}

.gap-9 {
  gap: 12rem !important;
}

.gap-10 {
  gap: 18rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.m-8 {
  margin: 9rem !important;
}

.m-9 {
  margin: 12rem !important;
}

.m-10 {
  margin: 18rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-7 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-8 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-9 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.mx-10 {
  margin-right: 18rem !important;
  margin-left: 18rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-7 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-8 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-9 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-10 {
  margin-top: 18rem !important;
  margin-bottom: 18rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 4.5rem !important;
}

.mt-7 {
  margin-top: 6rem !important;
}

.mt-8 {
  margin-top: 9rem !important;
}

.mt-9 {
  margin-top: 12rem !important;
}

.mt-10 {
  margin-top: 18rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 4.5rem !important;
}

.me-7 {
  margin-right: 6rem !important;
}

.me-8 {
  margin-right: 9rem !important;
}

.me-9 {
  margin-right: 12rem !important;
}

.me-10 {
  margin-right: 18rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 4.5rem !important;
}

.mb-7 {
  margin-bottom: 6rem !important;
}

.mb-8 {
  margin-bottom: 9rem !important;
}

.mb-9 {
  margin-bottom: 12rem !important;
}

.mb-10 {
  margin-bottom: 18rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 4.5rem !important;
}

.ms-7 {
  margin-left: 6rem !important;
}

.ms-8 {
  margin-left: 9rem !important;
}

.ms-9 {
  margin-left: 12rem !important;
}

.ms-10 {
  margin-left: 18rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.p-8 {
  padding: 9rem !important;
}

.p-9 {
  padding: 12rem !important;
}

.p-10 {
  padding: 18rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-7 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-8 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.px-9 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.px-10 {
  padding-right: 18rem !important;
  padding-left: 18rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-7 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-8 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-9 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.py-10 {
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 4.5rem !important;
}

.pt-7 {
  padding-top: 6rem !important;
}

.pt-8 {
  padding-top: 9rem !important;
}

.pt-9 {
  padding-top: 12rem !important;
}

.pt-10 {
  padding-top: 18rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3, .select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 4.5rem !important;
}

.pe-7 {
  padding-right: 6rem !important;
}

.pe-8 {
  padding-right: 9rem !important;
}

.pe-9 {
  padding-right: 12rem !important;
}

.pe-10 {
  padding-right: 18rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 4.5rem !important;
}

.pb-7 {
  padding-bottom: 6rem !important;
}

.pb-8 {
  padding-bottom: 9rem !important;
}

.pb-9 {
  padding-bottom: 12rem !important;
}

.pb-10 {
  padding-bottom: 18rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2, .select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 4.5rem !important;
}

.ps-7 {
  padding-left: 6rem !important;
}

.ps-8 {
  padding-left: 9rem !important;
}

.ps-9 {
  padding-left: 12rem !important;
}

.ps-10 {
  padding-left: 18rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-4 {
  font-size: 1.25rem !important;
}

.fs-5 {
  font-size: 1.125rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-xs {
  font-size: 0.75rem !important;
}

.fs-sm {
  font-size: 0.875rem !important;
}

.fs-lg {
  font-size: 1.25rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light, .select2-container--default .select2-selection--multiple .select2-selection__choice {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.tracking-tighter {
  letter-spacing: -0.05em !important;
}

.tracking-tight {
  letter-spacing: -0.025em !important;
}

.tracking-normal {
  letter-spacing: 0 !important;
}

.tracking-wide {
  letter-spacing: 0.025em !important;
}

.tracking-wider {
  letter-spacing: 0.05em !important;
}

.tracking-widest {
  letter-spacing: 0.1em !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .gap-sm-6 {
    gap: 4.5rem !important;
  }

  .gap-sm-7 {
    gap: 6rem !important;
  }

  .gap-sm-8 {
    gap: 9rem !important;
  }

  .gap-sm-9 {
    gap: 12rem !important;
  }

  .gap-sm-10 {
    gap: 18rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-6 {
    margin: 4.5rem !important;
  }

  .m-sm-7 {
    margin: 6rem !important;
  }

  .m-sm-8 {
    margin: 9rem !important;
  }

  .m-sm-9 {
    margin: 12rem !important;
  }

  .m-sm-10 {
    margin: 18rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-sm-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-sm-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-sm-10 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-sm-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-sm-10 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-6 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 6rem !important;
  }

  .mt-sm-8 {
    margin-top: 9rem !important;
  }

  .mt-sm-9 {
    margin-top: 12rem !important;
  }

  .mt-sm-10 {
    margin-top: 18rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-6 {
    margin-right: 4.5rem !important;
  }

  .me-sm-7 {
    margin-right: 6rem !important;
  }

  .me-sm-8 {
    margin-right: 9rem !important;
  }

  .me-sm-9 {
    margin-right: 12rem !important;
  }

  .me-sm-10 {
    margin-right: 18rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 9rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 12rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 18rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-6 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 6rem !important;
  }

  .ms-sm-8 {
    margin-left: 9rem !important;
  }

  .ms-sm-9 {
    margin-left: 12rem !important;
  }

  .ms-sm-10 {
    margin-left: 18rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .p-sm-6 {
    padding: 4.5rem !important;
  }

  .p-sm-7 {
    padding: 6rem !important;
  }

  .p-sm-8 {
    padding: 9rem !important;
  }

  .p-sm-9 {
    padding: 12rem !important;
  }

  .p-sm-10 {
    padding: 18rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-sm-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-sm-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-sm-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-sm-10 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-sm-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-sm-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-sm-10 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pt-sm-6 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 6rem !important;
  }

  .pt-sm-8 {
    padding-top: 9rem !important;
  }

  .pt-sm-9 {
    padding-top: 12rem !important;
  }

  .pt-sm-10 {
    padding-top: 18rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pe-sm-6 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 6rem !important;
  }

  .pe-sm-8 {
    padding-right: 9rem !important;
  }

  .pe-sm-9 {
    padding-right: 12rem !important;
  }

  .pe-sm-10 {
    padding-right: 18rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 6rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 9rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 12rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 18rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .ps-sm-6 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 6rem !important;
  }

  .ps-sm-8 {
    padding-left: 9rem !important;
  }

  .ps-sm-9 {
    padding-left: 12rem !important;
  }

  .ps-sm-10 {
    padding-left: 18rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .gap-md-6 {
    gap: 4.5rem !important;
  }

  .gap-md-7 {
    gap: 6rem !important;
  }

  .gap-md-8 {
    gap: 9rem !important;
  }

  .gap-md-9 {
    gap: 12rem !important;
  }

  .gap-md-10 {
    gap: 18rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-6 {
    margin: 4.5rem !important;
  }

  .m-md-7 {
    margin: 6rem !important;
  }

  .m-md-8 {
    margin: 9rem !important;
  }

  .m-md-9 {
    margin: 12rem !important;
  }

  .m-md-10 {
    margin: 18rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-md-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-md-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-md-10 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-md-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-md-10 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-6 {
    margin-top: 4.5rem !important;
  }

  .mt-md-7 {
    margin-top: 6rem !important;
  }

  .mt-md-8 {
    margin-top: 9rem !important;
  }

  .mt-md-9 {
    margin-top: 12rem !important;
  }

  .mt-md-10 {
    margin-top: 18rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-6 {
    margin-right: 4.5rem !important;
  }

  .me-md-7 {
    margin-right: 6rem !important;
  }

  .me-md-8 {
    margin-right: 9rem !important;
  }

  .me-md-9 {
    margin-right: 12rem !important;
  }

  .me-md-10 {
    margin-right: 18rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-6 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 6rem !important;
  }

  .mb-md-8 {
    margin-bottom: 9rem !important;
  }

  .mb-md-9 {
    margin-bottom: 12rem !important;
  }

  .mb-md-10 {
    margin-bottom: 18rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-6 {
    margin-left: 4.5rem !important;
  }

  .ms-md-7 {
    margin-left: 6rem !important;
  }

  .ms-md-8 {
    margin-left: 9rem !important;
  }

  .ms-md-9 {
    margin-left: 12rem !important;
  }

  .ms-md-10 {
    margin-left: 18rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .p-md-6 {
    padding: 4.5rem !important;
  }

  .p-md-7 {
    padding: 6rem !important;
  }

  .p-md-8 {
    padding: 9rem !important;
  }

  .p-md-9 {
    padding: 12rem !important;
  }

  .p-md-10 {
    padding: 18rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-md-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-md-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-md-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-md-10 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-md-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-md-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-md-10 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pt-md-6 {
    padding-top: 4.5rem !important;
  }

  .pt-md-7 {
    padding-top: 6rem !important;
  }

  .pt-md-8 {
    padding-top: 9rem !important;
  }

  .pt-md-9 {
    padding-top: 12rem !important;
  }

  .pt-md-10 {
    padding-top: 18rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pe-md-6 {
    padding-right: 4.5rem !important;
  }

  .pe-md-7 {
    padding-right: 6rem !important;
  }

  .pe-md-8 {
    padding-right: 9rem !important;
  }

  .pe-md-9 {
    padding-right: 12rem !important;
  }

  .pe-md-10 {
    padding-right: 18rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .pb-md-6 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 6rem !important;
  }

  .pb-md-8 {
    padding-bottom: 9rem !important;
  }

  .pb-md-9 {
    padding-bottom: 12rem !important;
  }

  .pb-md-10 {
    padding-bottom: 18rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .ps-md-6 {
    padding-left: 4.5rem !important;
  }

  .ps-md-7 {
    padding-left: 6rem !important;
  }

  .ps-md-8 {
    padding-left: 9rem !important;
  }

  .ps-md-9 {
    padding-left: 12rem !important;
  }

  .ps-md-10 {
    padding-left: 18rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .gap-lg-6 {
    gap: 4.5rem !important;
  }

  .gap-lg-7 {
    gap: 6rem !important;
  }

  .gap-lg-8 {
    gap: 9rem !important;
  }

  .gap-lg-9 {
    gap: 12rem !important;
  }

  .gap-lg-10 {
    gap: 18rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-6 {
    margin: 4.5rem !important;
  }

  .m-lg-7 {
    margin: 6rem !important;
  }

  .m-lg-8 {
    margin: 9rem !important;
  }

  .m-lg-9 {
    margin: 12rem !important;
  }

  .m-lg-10 {
    margin: 18rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-lg-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-lg-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-lg-10 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-lg-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-lg-10 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-6 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 6rem !important;
  }

  .mt-lg-8 {
    margin-top: 9rem !important;
  }

  .mt-lg-9 {
    margin-top: 12rem !important;
  }

  .mt-lg-10 {
    margin-top: 18rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-6 {
    margin-right: 4.5rem !important;
  }

  .me-lg-7 {
    margin-right: 6rem !important;
  }

  .me-lg-8 {
    margin-right: 9rem !important;
  }

  .me-lg-9 {
    margin-right: 12rem !important;
  }

  .me-lg-10 {
    margin-right: 18rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 9rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 12rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 18rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-6 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 6rem !important;
  }

  .ms-lg-8 {
    margin-left: 9rem !important;
  }

  .ms-lg-9 {
    margin-left: 12rem !important;
  }

  .ms-lg-10 {
    margin-left: 18rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .p-lg-6 {
    padding: 4.5rem !important;
  }

  .p-lg-7 {
    padding: 6rem !important;
  }

  .p-lg-8 {
    padding: 9rem !important;
  }

  .p-lg-9 {
    padding: 12rem !important;
  }

  .p-lg-10 {
    padding: 18rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-lg-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-lg-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-lg-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-lg-10 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-lg-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-lg-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-lg-10 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pt-lg-6 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 6rem !important;
  }

  .pt-lg-8 {
    padding-top: 9rem !important;
  }

  .pt-lg-9 {
    padding-top: 12rem !important;
  }

  .pt-lg-10 {
    padding-top: 18rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pe-lg-6 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 6rem !important;
  }

  .pe-lg-8 {
    padding-right: 9rem !important;
  }

  .pe-lg-9 {
    padding-right: 12rem !important;
  }

  .pe-lg-10 {
    padding-right: 18rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 6rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 9rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 12rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 18rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .ps-lg-6 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 6rem !important;
  }

  .ps-lg-8 {
    padding-left: 9rem !important;
  }

  .ps-lg-9 {
    padding-left: 12rem !important;
  }

  .ps-lg-10 {
    padding-left: 18rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .gap-xl-6 {
    gap: 4.5rem !important;
  }

  .gap-xl-7 {
    gap: 6rem !important;
  }

  .gap-xl-8 {
    gap: 9rem !important;
  }

  .gap-xl-9 {
    gap: 12rem !important;
  }

  .gap-xl-10 {
    gap: 18rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-6 {
    margin: 4.5rem !important;
  }

  .m-xl-7 {
    margin: 6rem !important;
  }

  .m-xl-8 {
    margin: 9rem !important;
  }

  .m-xl-9 {
    margin: 12rem !important;
  }

  .m-xl-10 {
    margin: 18rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xl-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-xl-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-xl-10 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xl-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-xl-10 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-6 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 6rem !important;
  }

  .mt-xl-8 {
    margin-top: 9rem !important;
  }

  .mt-xl-9 {
    margin-top: 12rem !important;
  }

  .mt-xl-10 {
    margin-top: 18rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-6 {
    margin-right: 4.5rem !important;
  }

  .me-xl-7 {
    margin-right: 6rem !important;
  }

  .me-xl-8 {
    margin-right: 9rem !important;
  }

  .me-xl-9 {
    margin-right: 12rem !important;
  }

  .me-xl-10 {
    margin-right: 18rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 9rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 12rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 18rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-6 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 6rem !important;
  }

  .ms-xl-8 {
    margin-left: 9rem !important;
  }

  .ms-xl-9 {
    margin-left: 12rem !important;
  }

  .ms-xl-10 {
    margin-left: 18rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .p-xl-6 {
    padding: 4.5rem !important;
  }

  .p-xl-7 {
    padding: 6rem !important;
  }

  .p-xl-8 {
    padding: 9rem !important;
  }

  .p-xl-9 {
    padding: 12rem !important;
  }

  .p-xl-10 {
    padding: 18rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xl-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-xl-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-xl-10 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xl-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-xl-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-xl-10 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pt-xl-6 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 6rem !important;
  }

  .pt-xl-8 {
    padding-top: 9rem !important;
  }

  .pt-xl-9 {
    padding-top: 12rem !important;
  }

  .pt-xl-10 {
    padding-top: 18rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pe-xl-6 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 6rem !important;
  }

  .pe-xl-8 {
    padding-right: 9rem !important;
  }

  .pe-xl-9 {
    padding-right: 12rem !important;
  }

  .pe-xl-10 {
    padding-right: 18rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 6rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 9rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 12rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 18rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .ps-xl-6 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 6rem !important;
  }

  .ps-xl-8 {
    padding-left: 9rem !important;
  }

  .ps-xl-9 {
    padding-left: 12rem !important;
  }

  .ps-xl-10 {
    padding-left: 18rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .gap-xxl-6 {
    gap: 4.5rem !important;
  }

  .gap-xxl-7 {
    gap: 6rem !important;
  }

  .gap-xxl-8 {
    gap: 9rem !important;
  }

  .gap-xxl-9 {
    gap: 12rem !important;
  }

  .gap-xxl-10 {
    gap: 18rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-6 {
    margin: 4.5rem !important;
  }

  .m-xxl-7 {
    margin: 6rem !important;
  }

  .m-xxl-8 {
    margin: 9rem !important;
  }

  .m-xxl-9 {
    margin: 12rem !important;
  }

  .m-xxl-10 {
    margin: 18rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xxl-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-xxl-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-xxl-10 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xxl-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-xxl-10 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-6 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 6rem !important;
  }

  .mt-xxl-8 {
    margin-top: 9rem !important;
  }

  .mt-xxl-9 {
    margin-top: 12rem !important;
  }

  .mt-xxl-10 {
    margin-top: 18rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-6 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 6rem !important;
  }

  .me-xxl-8 {
    margin-right: 9rem !important;
  }

  .me-xxl-9 {
    margin-right: 12rem !important;
  }

  .me-xxl-10 {
    margin-right: 18rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 9rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 12rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 18rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-6 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 6rem !important;
  }

  .ms-xxl-8 {
    margin-left: 9rem !important;
  }

  .ms-xxl-9 {
    margin-left: 12rem !important;
  }

  .ms-xxl-10 {
    margin-left: 18rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .p-xxl-6 {
    padding: 4.5rem !important;
  }

  .p-xxl-7 {
    padding: 6rem !important;
  }

  .p-xxl-8 {
    padding: 9rem !important;
  }

  .p-xxl-9 {
    padding: 12rem !important;
  }

  .p-xxl-10 {
    padding: 18rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xxl-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-xxl-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-xxl-10 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-xxl-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-xxl-10 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pt-xxl-6 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 6rem !important;
  }

  .pt-xxl-8 {
    padding-top: 9rem !important;
  }

  .pt-xxl-9 {
    padding-top: 12rem !important;
  }

  .pt-xxl-10 {
    padding-top: 18rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pe-xxl-6 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 6rem !important;
  }

  .pe-xxl-8 {
    padding-right: 9rem !important;
  }

  .pe-xxl-9 {
    padding-right: 12rem !important;
  }

  .pe-xxl-10 {
    padding-right: 18rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 9rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 12rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 18rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .ps-xxl-6 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 6rem !important;
  }

  .ps-xxl-8 {
    padding-left: 9rem !important;
  }

  .ps-xxl-9 {
    padding-left: 12rem !important;
  }

  .ps-xxl-10 {
    padding-left: 18rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }

  .fs-2 {
    font-size: 1.875rem !important;
  }

  .fs-3 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
b,
strong {
  font-weight: 700;
}

a {
  transition: color 0.12s ease-out;
}
@media (min-width: 576px) {
  a.link-fx {
    position: relative;
  }
  a.link-fx::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    content: "";
    background-color: #f4623a;
    visibility: hidden;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform 0.2s ease-out;
  }
}
a.link-fx:hover::before, a.link-fx:focus::before {
  visibility: visible;
  transform: scaleX(1);
}

p {
  line-height: 1.6;
}

.story p,
p.story {
  line-height: 1.6;
  font-size: 1.25rem;
  color: #495057;
}
@media (min-width: 576px) {
  .story p,
p.story {
    line-height: 1.8;
  }
}

.story h2, .story .h2,
.story h3,
.story .h3,
.story h4,
.story .h4 {
  margin-top: 3rem;
}

.content-heading {
  margin-bottom: 0.875rem;
  padding-top: 1rem;
  padding-bottom: 0.375rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75;
  border-bottom: 1px solid #dfe4f1;
}
.content-heading small, .content-heading .small {
  margin-top: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  color: #6c757d;
}
@media (min-width: 768px) {
  .content-heading {
    margin-bottom: 1.75rem;
    padding-top: 1.5rem;
  }
  .content-heading small, .content-heading .small {
    margin-top: 0;
  }
}
.block-content > .content-heading:first-child, .content > .content-heading:first-child {
  padding-top: 0 !important;
}
.content-heading .dropdown {
  line-height: 1.5;
}

small,
.small {
  font-weight: inherit;
}

.text-uppercase {
  letter-spacing: 0.0625rem;
}

.row.g-sm {
  --bs-gutter-x: 0.25rem;
}
.row.g-sm > .col,
.row.g-sm > [class*=col-] {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.row.g-sm .push,
.row.g-sm .block, .row.g-sm.items-push > div {
  margin-bottom: 0.25rem;
}

@media (max-width: 575.98px) {
  .row:not(.g-sm):not(.g-0) {
    --bs-gutter-x: 0.875rem;
  }
  .row:not(.g-sm):not(.g-0) > .col,
.row:not(.g-sm):not(.g-0) > [class*=col-] {
    padding-left: 0.4375rem;
    padding-right: 0.4375rem;
  }
}
.table:not(.table-borderless) > :not(:last-child) > :last-child > * {
  border-bottom-width: 2px;
}

.table-vcenter th,
.table-vcenter td {
  vertical-align: middle;
}

.js-table-checkable tbody tr,
.js-table-sections-header > tr {
  cursor: pointer;
}

.js-table-sections-header > tr > td:first-child > i {
  transition: transform 0.15s ease-out;
}
.js-table-sections-header + tbody {
  display: none;
}
.js-table-sections-header.show > tr > td:first-child > i {
  transform: rotate(90deg);
}
.js-table-sections-header.show + tbody {
  display: table-row-group;
}

.form-switch {
  min-height: 2em;
  line-height: 2em;
}
.form-switch .form-check-input {
  height: 1.5em;
}

.form-block {
  position: relative;
  padding: 0;
  z-index: 1;
}
.form-block .form-check-input {
  position: absolute;
  left: 0;
  z-index: -1;
  opacity: 0;
}
.form-block .form-check-label {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #dfe4f1;
  border-radius: 0.2rem;
  cursor: pointer;
}
.form-block .form-check-label:hover {
  border-color: #cdd6e9;
}
.form-block .form-check-label::before {
  position: absolute;
  top: 0rem;
  right: 0rem;
  width: 1.5rem;
  height: 1.5rem;
  display: none;
  line-height: 1.5rem;
  color: #fff;
  font-size: 0.625rem;
  text-align: center;
  background-color: #f4623a;
  border-bottom-left-radius: 0.25rem;
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f00c";
  z-index: 1;
}
.form-block .form-check-input:checked ~ .form-check-label {
  border-color: #f4623a;
}
.form-block .form-check-input:focus ~ .form-check-label {
  border-color: #f4623a;
  box-shadow: 0 0 0 0.25rem rgba(244, 98, 58, 0.25);
}
.form-block .form-check-input:checked ~ .form-check-label::before {
  display: block;
}
.form-block .form-check-input:disabled + .form-check-label,
.form-block .form-check-input[readonly] + .form-check-label {
  opacity: 0.5;
  cursor: not-allowed;
}
.form-block .form-check-input:disabled:not([checked]) + .form-check-label:hover,
.form-block .form-check-input[readonly]:not([checked]) + .form-check-label:hover {
  border-color: #dfe4f1;
}

.form-control, .select2-container--default .select2-selection--multiple, .select2-container--default .select2-selection--single {
  font-family: "Source sans pro", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.form-control.form-control-alt, .select2-container--default .form-control-alt.select2-selection--multiple, .select2-container--default .form-control-alt.select2-selection--single {
  border-color: #edf0f7;
  background-color: #edf0f7;
  transition: none;
}
.form-control.form-control-alt:focus, .select2-container--default .form-control-alt.select2-selection--multiple:focus, .select2-container--default .form-control-alt.select2-selection--single:focus {
  border-color: #dfe4f1;
  background-color: #dfe4f1;
  box-shadow: none;
}
.form-control.form-control-alt.is-valid, .select2-container--default .form-control-alt.is-valid.select2-selection--multiple, .select2-container--default .form-control-alt.is-valid.select2-selection--single {
  border-color: #ddede5;
  background-color: #ddede5;
}
.form-control.form-control-alt.is-valid:focus, .select2-container--default .form-control-alt.is-valid.select2-selection--multiple:focus, .select2-container--default .form-control-alt.is-valid.select2-selection--single:focus {
  border-color: #cce4d8;
  background-color: #cce4d8;
}
.form-control.form-control-alt.is-invalid, .select2-container--default .form-control-alt.is-invalid.select2-selection--multiple, .select2-container--default .form-control-alt.is-invalid.select2-selection--single {
  border-color: #fde1e2;
  background-color: #fde1e2;
}
.form-control.form-control-alt.is-invalid:focus, .select2-container--default .form-control-alt.is-invalid.select2-selection--multiple:focus, .select2-container--default .form-control-alt.is-invalid.select2-selection--single:focus {
  border-color: #fbc9cb;
  background-color: #fbc9cb;
}

.form-select {
  font-family: "Source sans pro", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.form-select.form-control-alt {
  border-color: #edf0f7;
  background-color: #edf0f7;
  transition: none;
}
.form-select.form-control-alt:focus {
  border-color: #dfe4f1;
  background-color: #dfe4f1;
  box-shadow: none;
}
.form-select.form-control-alt.is-valid {
  border-color: #ddede5;
  background-color: #ddede5;
}
.form-select.form-control-alt.is-valid:focus {
  border-color: #cce4d8;
  background-color: #cce4d8;
}
.form-select.form-control-alt.is-invalid {
  border-color: #fde1e2;
  background-color: #fde1e2;
}
.form-select.form-control-alt.is-invalid:focus {
  border-color: #fbc9cb;
  background-color: #fbc9cb;
}

.input-group-text.input-group-text-alt {
  background-color: #dfe4f1;
  border-color: #dfe4f1;
}

.btn .si {
  position: relative;
  top: 1px;
}

.btn-secondary {
  color: #fff;
  background-color: #495057;
  border-color: #495057;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #3a4046;
  border-color: #373c41;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #3a4046;
  border-color: #373c41;
  box-shadow: 0 0 0 0.25rem rgba(100, 106, 112, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #3a4046;
  border-color: #373c41;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(100, 106, 112, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #495057;
  border-color: #495057;
}

.btn-alt-primary {
  color: #923b23;
  background-color: #fcd8ce;
  border-color: #fcd8ce;
}
.btn-alt-primary:hover {
  color: #622717;
  background-color: #fab19d;
  border-color: #fab19d;
}
.btn-check:focus + .btn-alt-primary, .btn-alt-primary:focus {
  color: #622717;
  background-color: #fab19d;
  border-color: #fab19d;
  box-shadow: 0 0 0 0.25rem rgba(236, 192, 180, 0.5);
}
.btn-check:checked + .btn-alt-primary, .btn-check:active + .btn-alt-primary, .btn-alt-primary:active, .btn-alt-primary.active, .show > .btn-alt-primary.dropdown-toggle {
  color: #000;
  background-color: #fde0d8;
  border-color: #fcdcd3;
}
.btn-check:checked + .btn-alt-primary:focus, .btn-check:active + .btn-alt-primary:focus, .btn-alt-primary:active:focus, .btn-alt-primary.active:focus, .show > .btn-alt-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(236, 192, 180, 0.5);
}
.btn-alt-primary:disabled, .btn-alt-primary.disabled {
  color: #000;
  background-color: #fcd8ce;
  border-color: #fcd8ce;
}

.btn-alt-secondary {
  color: #212529;
  background-color: #edf0f7;
  border-color: #edf0f7;
}
.btn-alt-secondary:hover {
  color: #1c1f23;
  background-color: #d5d8de;
  border-color: #d5d8de;
}
.btn-check:focus + .btn-alt-secondary, .btn-alt-secondary:focus {
  color: #1c1f23;
  background-color: #d5d8de;
  border-color: #d5d8de;
  box-shadow: 0 0 0 0.25rem rgba(206, 210, 216, 0.5);
}
.btn-check:checked + .btn-alt-secondary, .btn-check:active + .btn-alt-secondary, .btn-alt-secondary:active, .btn-alt-secondary.active, .show > .btn-alt-secondary.dropdown-toggle {
  color: #000;
  background-color: #f1f3f9;
  border-color: #eff2f8;
}
.btn-check:checked + .btn-alt-secondary:focus, .btn-check:active + .btn-alt-secondary:focus, .btn-alt-secondary:active:focus, .btn-alt-secondary.active:focus, .show > .btn-alt-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(206, 210, 216, 0.5);
}
.btn-alt-secondary:disabled, .btn-alt-secondary.disabled {
  color: #000;
  background-color: #edf0f7;
  border-color: #edf0f7;
}

.btn-alt-success {
  color: #0f5132;
  background-color: #c6e1d4;
  border-color: #c6e1d4;
}
.btn-alt-success:hover {
  color: #0a3622;
  background-color: #8cc3aa;
  border-color: #8cc3aa;
}
.btn-check:focus + .btn-alt-success, .btn-alt-success:focus {
  color: #0a3622;
  background-color: #8cc3aa;
  border-color: #8cc3aa;
  box-shadow: 0 0 0 0.25rem rgba(171, 203, 188, 0.5);
}
.btn-check:checked + .btn-alt-success, .btn-check:active + .btn-alt-success, .btn-alt-success:active, .btn-alt-success.active, .show > .btn-alt-success.dropdown-toggle {
  color: #000;
  background-color: #d1e7dd;
  border-color: #cce4d8;
}
.btn-check:checked + .btn-alt-success:focus, .btn-check:active + .btn-alt-success:focus, .btn-alt-success:active:focus, .btn-alt-success.active:focus, .show > .btn-alt-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(171, 203, 188, 0.5);
}
.btn-alt-success:disabled, .btn-alt-success.disabled {
  color: #000;
  background-color: #c6e1d4;
  border-color: #c6e1d4;
}

.btn-alt-info {
  color: #245686;
  background-color: #cee3f7;
  border-color: #cee3f7;
}
.btn-alt-info:hover {
  color: #183a59;
  background-color: #9ec8ef;
  border-color: #9ec8ef;
}
.btn-check:focus + .btn-alt-info, .btn-alt-info:focus {
  color: #183a59;
  background-color: #9ec8ef;
  border-color: #9ec8ef;
  box-shadow: 0 0 0 0.25rem rgba(181, 206, 230, 0.5);
}
.btn-check:checked + .btn-alt-info, .btn-check:active + .btn-alt-info, .btn-alt-info:active, .btn-alt-info.active, .show > .btn-alt-info.dropdown-toggle {
  color: #000;
  background-color: #d8e9f9;
  border-color: #d3e6f8;
}
.btn-check:checked + .btn-alt-info:focus, .btn-check:active + .btn-alt-info:focus, .btn-alt-info:active:focus, .btn-alt-info.active:focus, .show > .btn-alt-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(181, 206, 230, 0.5);
}
.btn-alt-info:disabled, .btn-alt-info.disabled {
  color: #000;
  background-color: #cee3f7;
  border-color: #cee3f7;
}

.btn-alt-warning {
  color: #8a5f0e;
  background-color: #f9e7c5;
  border-color: #f9e7c5;
}
.btn-alt-warning:hover {
  color: #5c4009;
  background-color: #f3cf8b;
  border-color: #f3cf8b;
}
.btn-check:focus + .btn-alt-warning, .btn-alt-warning:focus {
  color: #5c4009;
  background-color: #f3cf8b;
  border-color: #f3cf8b;
  box-shadow: 0 0 0 0.25rem rgba(232, 211, 170, 0.5);
}
.btn-check:checked + .btn-alt-warning, .btn-check:active + .btn-alt-warning, .btn-alt-warning:active, .btn-alt-warning.active, .show > .btn-alt-warning.dropdown-toggle {
  color: #000;
  background-color: #faecd1;
  border-color: #fae9cb;
}
.btn-check:checked + .btn-alt-warning:focus, .btn-check:active + .btn-alt-warning:focus, .btn-alt-warning:active:focus, .btn-alt-warning.active:focus, .show > .btn-alt-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 211, 170, 0.5);
}
.btn-alt-warning:disabled, .btn-alt-warning.disabled {
  color: #000;
  background-color: #f9e7c5;
  border-color: #f9e7c5;
}

.btn-alt-danger {
  color: #912123;
  background-color: #fccdce;
  border-color: #fccdce;
}
.btn-alt-danger:hover {
  color: #601618;
  background-color: #f89b9d;
  border-color: #f89b9d;
}
.btn-check:focus + .btn-alt-danger, .btn-alt-danger:focus {
  color: #601618;
  background-color: #f89b9d;
  border-color: #f89b9d;
  box-shadow: 0 0 0 0.25rem rgba(236, 179, 180, 0.5);
}
.btn-check:checked + .btn-alt-danger, .btn-check:active + .btn-alt-danger, .btn-alt-danger:active, .btn-alt-danger.active, .show > .btn-alt-danger.dropdown-toggle {
  color: #000;
  background-color: #fdd7d8;
  border-color: #fcd2d3;
}
.btn-check:checked + .btn-alt-danger:focus, .btn-check:active + .btn-alt-danger:focus, .btn-alt-danger:active:focus, .btn-alt-danger.active:focus, .show > .btn-alt-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(236, 179, 180, 0.5);
}
.btn-alt-danger:disabled, .btn-alt-danger.disabled {
  color: #000;
  background-color: #fccdce;
  border-color: #fccdce;
}

.btn-hero.btn-primary {
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.75rem rgba(214, 55, 12, 0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.12s ease-out;
}
.btn-hero.btn-primary:hover {
  box-shadow: 0 0.375rem 0.75rem rgba(214, 55, 12, 0.4);
  transform: translateY(-1px);
}
.btn-check:focus + .btn-hero.btn-primary, .btn-hero.btn-primary:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(214, 55, 12, 0.25);
}
.btn-check:checked + .btn-hero.btn-primary, .btn-check:active + .btn-hero.btn-primary, .btn-hero.btn-primary:active, .btn-hero.btn-primary.active, .show > .btn-hero.btn-primary.dropdown-toggle {
  box-shadow: 0 0.125rem 0.75rem rgba(214, 55, 12, 0.25);
  transform: translateY(0);
}
.btn-check:checked + .btn-hero.btn-primary:focus, .btn-check:active + .btn-hero.btn-primary:focus, .btn-hero.btn-primary:active:focus, .btn-hero.btn-primary.active:focus, .show > .btn-hero.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(214, 55, 12, 0.25);
}
.btn-hero.btn-primary.disabled, .btn-hero.btn-primary:disabled {
  box-shadow: 0 0.125rem 0.75rem rgba(214, 55, 12, 0.25);
  transform: translateY(0);
}
.btn-hero.btn-secondary {
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.75rem rgba(73, 79, 84, 0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.12s ease-out;
}
.btn-hero.btn-secondary:hover {
  box-shadow: 0 0.375rem 0.75rem rgba(73, 79, 84, 0.4);
  transform: translateY(-1px);
}
.btn-check:focus + .btn-hero.btn-secondary, .btn-hero.btn-secondary:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(73, 79, 84, 0.25);
}
.btn-check:checked + .btn-hero.btn-secondary, .btn-check:active + .btn-hero.btn-secondary, .btn-hero.btn-secondary:active, .btn-hero.btn-secondary.active, .show > .btn-hero.btn-secondary.dropdown-toggle {
  box-shadow: 0 0.125rem 0.75rem rgba(73, 79, 84, 0.25);
  transform: translateY(0);
}
.btn-check:checked + .btn-hero.btn-secondary:focus, .btn-check:active + .btn-hero.btn-secondary:focus, .btn-hero.btn-secondary:active:focus, .btn-hero.btn-secondary.active:focus, .show > .btn-hero.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(73, 79, 84, 0.25);
}
.btn-hero.btn-secondary.disabled, .btn-hero.btn-secondary:disabled {
  box-shadow: 0 0.125rem 0.75rem rgba(73, 79, 84, 0.25);
  transform: translateY(0);
}
.btn-hero.btn-success {
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.75rem rgba(13, 70, 44, 0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.12s ease-out;
}
.btn-hero.btn-success:hover {
  box-shadow: 0 0.375rem 0.75rem rgba(13, 70, 44, 0.4);
  transform: translateY(-1px);
}
.btn-check:focus + .btn-hero.btn-success, .btn-hero.btn-success:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(13, 70, 44, 0.25);
}
.btn-check:checked + .btn-hero.btn-success, .btn-check:active + .btn-hero.btn-success, .btn-hero.btn-success:active, .btn-hero.btn-success.active, .show > .btn-hero.btn-success.dropdown-toggle {
  box-shadow: 0 0.125rem 0.75rem rgba(13, 70, 44, 0.25);
  transform: translateY(0);
}
.btn-check:checked + .btn-hero.btn-success:focus, .btn-check:active + .btn-hero.btn-success:focus, .btn-hero.btn-success:active:focus, .btn-hero.btn-success.active:focus, .show > .btn-hero.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(13, 70, 44, 0.25);
}
.btn-hero.btn-success.disabled, .btn-hero.btn-success:disabled {
  box-shadow: 0 0.125rem 0.75rem rgba(13, 70, 44, 0.25);
  transform: translateY(0);
}
.btn-hero.btn-info {
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.75rem rgba(29, 106, 177, 0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.12s ease-out;
}
.btn-hero.btn-info:hover {
  box-shadow: 0 0.375rem 0.75rem rgba(29, 106, 177, 0.4);
  transform: translateY(-1px);
}
.btn-check:focus + .btn-hero.btn-info, .btn-hero.btn-info:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(29, 106, 177, 0.25);
}
.btn-check:checked + .btn-hero.btn-info, .btn-check:active + .btn-hero.btn-info, .btn-hero.btn-info:active, .btn-hero.btn-info.active, .show > .btn-hero.btn-info.dropdown-toggle {
  box-shadow: 0 0.125rem 0.75rem rgba(29, 106, 177, 0.25);
  transform: translateY(0);
}
.btn-check:checked + .btn-hero.btn-info:focus, .btn-check:active + .btn-hero.btn-info:focus, .btn-hero.btn-info:active:focus, .btn-hero.btn-info.active:focus, .show > .btn-hero.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(29, 106, 177, 0.25);
}
.btn-hero.btn-info.disabled, .btn-hero.btn-info:disabled {
  box-shadow: 0 0.125rem 0.75rem rgba(29, 106, 177, 0.25);
  transform: translateY(0);
}
.btn-hero.btn-warning {
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.75rem rgba(160, 111, 16, 0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.12s ease-out;
}
.btn-hero.btn-warning:hover {
  box-shadow: 0 0.375rem 0.75rem rgba(160, 111, 16, 0.4);
  transform: translateY(-1px);
}
.btn-check:focus + .btn-hero.btn-warning, .btn-hero.btn-warning:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(160, 111, 16, 0.25);
}
.btn-check:checked + .btn-hero.btn-warning, .btn-check:active + .btn-hero.btn-warning, .btn-hero.btn-warning:active, .btn-hero.btn-warning.active, .show > .btn-hero.btn-warning.dropdown-toggle {
  box-shadow: 0 0.125rem 0.75rem rgba(160, 111, 16, 0.25);
  transform: translateY(0);
}
.btn-check:checked + .btn-hero.btn-warning:focus, .btn-check:active + .btn-hero.btn-warning:focus, .btn-hero.btn-warning:active:focus, .btn-hero.btn-warning.active:focus, .show > .btn-hero.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(160, 111, 16, 0.25);
}
.btn-hero.btn-warning.disabled, .btn-hero.btn-warning:disabled {
  box-shadow: 0 0.125rem 0.75rem rgba(160, 111, 16, 0.25);
  transform: translateY(0);
}
.btn-hero.btn-danger {
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.75rem rgba(205, 14, 18, 0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.12s ease-out;
}
.btn-hero.btn-danger:hover {
  box-shadow: 0 0.375rem 0.75rem rgba(205, 14, 18, 0.4);
  transform: translateY(-1px);
}
.btn-check:focus + .btn-hero.btn-danger, .btn-hero.btn-danger:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(205, 14, 18, 0.25);
}
.btn-check:checked + .btn-hero.btn-danger, .btn-check:active + .btn-hero.btn-danger, .btn-hero.btn-danger:active, .btn-hero.btn-danger.active, .show > .btn-hero.btn-danger.dropdown-toggle {
  box-shadow: 0 0.125rem 0.75rem rgba(205, 14, 18, 0.25);
  transform: translateY(0);
}
.btn-check:checked + .btn-hero.btn-danger:focus, .btn-check:active + .btn-hero.btn-danger:focus, .btn-hero.btn-danger:active:focus, .btn-hero.btn-danger.active:focus, .show > .btn-hero.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(205, 14, 18, 0.25);
}
.btn-hero.btn-danger.disabled, .btn-hero.btn-danger:disabled {
  box-shadow: 0 0.125rem 0.75rem rgba(205, 14, 18, 0.25);
  transform: translateY(0);
}
.btn-hero.btn-dark {
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.75rem rgba(18, 20, 22, 0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.12s ease-out;
}
.btn-hero.btn-dark:hover {
  box-shadow: 0 0.375rem 0.75rem rgba(18, 20, 22, 0.4);
  transform: translateY(-1px);
}
.btn-check:focus + .btn-hero.btn-dark, .btn-hero.btn-dark:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(18, 20, 22, 0.25);
}
.btn-check:checked + .btn-hero.btn-dark, .btn-check:active + .btn-hero.btn-dark, .btn-hero.btn-dark:active, .btn-hero.btn-dark.active, .show > .btn-hero.btn-dark.dropdown-toggle {
  box-shadow: 0 0.125rem 0.75rem rgba(18, 20, 22, 0.25);
  transform: translateY(0);
}
.btn-check:checked + .btn-hero.btn-dark:focus, .btn-check:active + .btn-hero.btn-dark:focus, .btn-hero.btn-dark:active:focus, .btn-hero.btn-dark.active:focus, .show > .btn-hero.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(18, 20, 22, 0.25);
}
.btn-hero.btn-dark.disabled, .btn-hero.btn-dark:disabled {
  box-shadow: 0 0.125rem 0.75rem rgba(18, 20, 22, 0.25);
  transform: translateY(0);
}
.btn-hero.btn-light {
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.75rem rgba(188, 200, 225, 0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.12s ease-out;
}
.btn-hero.btn-light:hover {
  box-shadow: 0 0.375rem 0.75rem rgba(188, 200, 225, 0.4);
  transform: translateY(-1px);
}
.btn-check:focus + .btn-hero.btn-light, .btn-hero.btn-light:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(188, 200, 225, 0.25);
}
.btn-check:checked + .btn-hero.btn-light, .btn-check:active + .btn-hero.btn-light, .btn-hero.btn-light:active, .btn-hero.btn-light.active, .show > .btn-hero.btn-light.dropdown-toggle {
  box-shadow: 0 0.125rem 0.75rem rgba(188, 200, 225, 0.25);
  transform: translateY(0);
}
.btn-check:checked + .btn-hero.btn-light:focus, .btn-check:active + .btn-hero.btn-light:focus, .btn-hero.btn-light:active:focus, .btn-hero.btn-light.active:focus, .show > .btn-hero.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(188, 200, 225, 0.25);
}
.btn-hero.btn-light.disabled, .btn-hero.btn-light:disabled {
  box-shadow: 0 0.125rem 0.75rem rgba(188, 200, 225, 0.25);
  transform: translateY(0);
}
.btn-hero.btn-lg, .btn-group-lg > .btn-hero.btn {
  padding: 0.875rem 2.25rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.btn-hero.btn-sm, .btn-group-sm > .btn-hero.btn {
  padding: 0.375rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.page-header-dark #page-header .btn-alt-secondary,
.sidebar-dark #sidebar .btn-alt-secondary,
#sidebar .bg-header-dark .content-header .btn-alt-secondary,
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary {
  color: #fff;
  background-color: #e17152;
  border-color: #e17152;
}
.page-header-dark #page-header .btn-alt-secondary:hover,
.sidebar-dark #sidebar .btn-alt-secondary:hover,
#sidebar .bg-header-dark .content-header .btn-alt-secondary:hover,
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary:hover {
  color: #fff;
  background-color: #e58267;
  border-color: #e58267;
}
.btn-check:focus + .page-header-dark #page-header .btn-alt-secondary, .page-header-dark #page-header .btn-alt-secondary:focus,
.btn-check:focus + .sidebar-dark #sidebar .btn-alt-secondary,
.sidebar-dark #sidebar .btn-alt-secondary:focus,
.btn-check:focus + #sidebar .bg-header-dark .content-header .btn-alt-secondary,
#sidebar .bg-header-dark .content-header .btn-alt-secondary:focus,
.btn-check:focus + .page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary,
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary:focus {
  color: #fff;
  background-color: #e58267;
  border-color: #e58267;
  box-shadow: 0 0 0 0.25rem rgba(230, 134, 108, 0.5);
}
.btn-check:checked + .page-header-dark #page-header .btn-alt-secondary, .btn-check:active + .page-header-dark #page-header .btn-alt-secondary, .page-header-dark #page-header .btn-alt-secondary:active, .page-header-dark #page-header .btn-alt-secondary.active, .show > .page-header-dark #page-header .btn-alt-secondary.dropdown-toggle,
.btn-check:checked + .sidebar-dark #sidebar .btn-alt-secondary,
.btn-check:active + .sidebar-dark #sidebar .btn-alt-secondary,
.sidebar-dark #sidebar .btn-alt-secondary:active,
.sidebar-dark #sidebar .btn-alt-secondary.active,
.show > .sidebar-dark #sidebar .btn-alt-secondary.dropdown-toggle,
.btn-check:checked + #sidebar .bg-header-dark .content-header .btn-alt-secondary,
.btn-check:active + #sidebar .bg-header-dark .content-header .btn-alt-secondary,
#sidebar .bg-header-dark .content-header .btn-alt-secondary:active,
#sidebar .bg-header-dark .content-header .btn-alt-secondary.active,
.show > #sidebar .bg-header-dark .content-header .btn-alt-secondary.dropdown-toggle,
.btn-check:checked + .page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary,
.btn-check:active + .page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary,
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary:active,
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary.active,
.show > .page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary.dropdown-toggle {
  color: #fff;
  background-color: #b45a42;
  border-color: #a9553e;
}
.btn-check:checked + .page-header-dark #page-header .btn-alt-secondary:focus, .btn-check:active + .page-header-dark #page-header .btn-alt-secondary:focus, .page-header-dark #page-header .btn-alt-secondary:active:focus, .page-header-dark #page-header .btn-alt-secondary.active:focus, .show > .page-header-dark #page-header .btn-alt-secondary.dropdown-toggle:focus,
.btn-check:checked + .sidebar-dark #sidebar .btn-alt-secondary:focus,
.btn-check:active + .sidebar-dark #sidebar .btn-alt-secondary:focus,
.sidebar-dark #sidebar .btn-alt-secondary:active:focus,
.sidebar-dark #sidebar .btn-alt-secondary.active:focus,
.show > .sidebar-dark #sidebar .btn-alt-secondary.dropdown-toggle:focus,
.btn-check:checked + #sidebar .bg-header-dark .content-header .btn-alt-secondary:focus,
.btn-check:active + #sidebar .bg-header-dark .content-header .btn-alt-secondary:focus,
#sidebar .bg-header-dark .content-header .btn-alt-secondary:active:focus,
#sidebar .bg-header-dark .content-header .btn-alt-secondary.active:focus,
.show > #sidebar .bg-header-dark .content-header .btn-alt-secondary.dropdown-toggle:focus,
.btn-check:checked + .page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary:focus,
.btn-check:active + .page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary:focus,
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary:active:focus,
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary.active:focus,
.show > .page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(230, 134, 108, 0.5);
}
.page-header-dark #page-header .btn-alt-secondary:disabled, .page-header-dark #page-header .btn-alt-secondary.disabled,
.sidebar-dark #sidebar .btn-alt-secondary:disabled,
.sidebar-dark #sidebar .btn-alt-secondary.disabled,
#sidebar .bg-header-dark .content-header .btn-alt-secondary:disabled,
#sidebar .bg-header-dark .content-header .btn-alt-secondary.disabled,
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary:disabled,
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary.disabled {
  color: #fff;
  background-color: #e17152;
  border-color: #e17152;
}

.fade {
  transition: opacity 0.15s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade.fade-up, .fade.fade-right, .fade.fade-left {
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .fade.fade-up, .fade.fade-right, .fade.fade-left {
    transition: none;
  }
}
.fade.fade-up:not(.show) {
  transform: translateY(50px);
}
.fade.fade-right:not(.show) {
  transform: translateX(-50px);
}
.fade.fade-left:not(.show) {
  transform: translateX(50px);
}

.dropdown-menu {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.08);
}
.dropdown-menu.dropdown-menu-md {
  min-width: 200px;
}
.dropdown-menu.dropdown-menu-lg, .dropdown-menu.dropdown-menu-xl, .dropdown-menu.dropdown-menu-xxl {
  min-width: 300px;
}
.dropdown-menu.dropdown-menu-mega {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
  display: block;
  visibility: hidden;
  transform: translateY(10px) scale(0.98);
  transform-origin: top left;
}
.dropdown-menu.dropdown-menu-mega.show {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 1200px) {
  .dropdown-menu.dropdown-menu-xl {
    min-width: 450px;
  }
  .dropdown-menu.dropdown-menu-xxl {
    min-width: 600px;
  }
}

.dropdown-item {
  margin-bottom: 0.25rem;
  border-radius: 0.25rem;
}

.nav-pills .nav-link:hover, .nav-pills .nav-link:focus {
  background-color: #edf0f7;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #f4623a;
}

.nav-tabs-block {
  background-color: #f8f9fc;
  border-bottom: none;
}
.nav-tabs-block .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs-block .nav-link:hover, .nav-tabs-block .nav-link:focus {
  color: #f4623a;
  background-color: #edf0f7;
  border-color: transparent;
}
.nav-tabs-block .nav-link.active,
.nav-tabs-block .nav-item.show .nav-link {
  color: #343a40;
  background-color: #fff;
  border-color: transparent;
}

.nav-tabs-alt {
  background-color: transparent;
  border-bottom-color: #dfe4f1;
}
.nav-tabs-alt .nav-item {
  margin-bottom: -2px;
}
.nav-tabs-alt .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs-alt .nav-link:hover, .nav-tabs-alt .nav-link:focus {
  color: #f4623a;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #f4623a;
}
.nav-tabs-alt .nav-link.active,
.nav-tabs-alt .nav-item.show .nav-link {
  color: #343a40;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #f4623a;
}

.nav-items {
  padding-left: 0;
  list-style: none;
}
.nav-items a:hover {
  background-color: #f8f9fc;
}
.nav-items a:active {
  background-color: #edf0f7;
}
.nav-items > li:not(:last-child) > a {
  border-bottom: 1px solid #edf0f7;
}

.modal-dialog.modal-dialog-top {
  margin-top: 0 !important;
}

.modal.fade .modal-dialog {
  transition-duration: 0.2s;
}
.modal.fade .modal-dialog.modal-dialog-popin {
  transform: scale(1.25, 1.25);
}
.modal.fade .modal-dialog.modal-dialog-popout {
  transform: scale(0.85, 0.85);
}
.modal.fade .modal-dialog.modal-dialog-slideup {
  transform: translate(0, 30%);
}
.modal.fade .modal-dialog.modal-dialog-slideright {
  transform: translate(-30%, 0);
}
.modal.fade .modal-dialog.modal-dialog-slideleft {
  transform: translate(30%, 0);
}
.modal.fade .modal-dialog.modal-dialog-fromright {
  transform: translateX(30%) rotate(10deg) scale(0.75);
}
.modal.fade .modal-dialog.modal-dialog-fromleft {
  transform: translateX(-30%) rotate(-10deg) scale(0.75);
}
.modal.show .modal-dialog.modal-dialog-popin, .modal.show .modal-dialog.modal-dialog-popout {
  transform: scale(1, 1);
}
.modal.show .modal-dialog.modal-dialog-slideup, .modal.show .modal-dialog.modal-dialog-slideright, .modal.show .modal-dialog.modal-dialog-slideleft {
  transform: translate(0, 0);
}
.modal.show .modal-dialog.modal-dialog-fromright, .modal.show .modal-dialog.modal-dialog-fromleft {
  transform: translateX(0) rotate(0) scale(1);
}

#page-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
}

#page-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1033;
  opacity: 0;
  transition: opacity 0.25s ease-out;
  transform: translateY(-100%);
}
@media (min-width: 992px) {
  .side-overlay-o.enable-page-overlay #page-overlay {
    transform: translateY(0);
    opacity: 1;
  }
}

#main-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 100%;
}

#page-footer {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3rem;
}
#page-footer.footer-static {
  display: block;
  min-height: auto;
}

.content {
  width: 100%;
  margin: 0 auto;
  padding: 0.875rem 0.875rem 1px;
  overflow-x: visible;
}
.content > .pull-t,
.content > .pull-y {
  margin-top: -0.875rem;
}
.content > .pull-b,
.content > .pull-y {
  margin-bottom: -1px;
}
.content > .pull-r,
.content > .pull-x {
  margin-right: -0.875rem;
}
.content > .pull-r,
.content > .pull-x {
  margin-left: -0.875rem;
}
.content > .pull {
  margin: -0.875rem -0.875rem -1px;
}
.content.content-full {
  padding-bottom: 0.875rem;
}
.content.content-full > .pull-b,
.content.content-full > .pull-y,
.content.content-full > .pull {
  margin-bottom: -0.875rem;
}

.content p,
.content .push,
.content .block,
.content .items-push > div {
  margin-bottom: 0.875rem;
}
.content .items-push-2x > div {
  margin-bottom: 1.75rem;
}
.content .items-push-3x > div {
  margin-bottom: 2.625rem;
}
@media (min-width: 768px) {
  .content {
    width: 100%;
    margin: 0 auto;
    padding: 1.75rem 1.75rem 1px;
    overflow-x: visible;
  }
  .content > .pull-t,
.content > .pull-y {
    margin-top: -1.75rem;
  }
  .content > .pull-b,
.content > .pull-y {
    margin-bottom: -1px;
  }
  .content > .pull-r,
.content > .pull-x {
    margin-right: -1.75rem;
  }
  .content > .pull-r,
.content > .pull-x {
    margin-left: -1.75rem;
  }
  .content > .pull {
    margin: -1.75rem -1.75rem -1px;
  }
  .content.content-full {
    padding-bottom: 1.75rem;
  }
  .content.content-full > .pull-b,
.content.content-full > .pull-y,
.content.content-full > .pull {
    margin-bottom: -1.75rem;
  }

  .content p,
.content .push,
.content .block,
.content .items-push > div {
    margin-bottom: 1.75rem;
  }
  .content .items-push-2x > div {
    margin-bottom: 3.5rem;
  }
  .content .items-push-3x > div {
    margin-bottom: 5.25rem;
  }
}
@media (min-width: 1200px) {
  .content.content-narrow {
    width: 92% !important;
  }
}
.content.content-boxed {
  max-width: 1200px !important;
}
.content.content-top {
  padding-top: 4.475rem;
}
@media (min-width: 992px) {
  .content.content-top {
    padding-top: 5.35rem;
  }
}

.content-section-top {
  padding-top: 3.6rem;
}

.content-max-width {
  max-width: 1920px;
}

.content-side {
  width: 100%;
  margin: 0 auto;
  padding: 1.125rem 1.125rem 1px;
  overflow-x: hidden;
}
.content-side > .pull-t,
.content-side > .pull-y {
  margin-top: -1.125rem;
}
.content-side > .pull-b,
.content-side > .pull-y {
  margin-bottom: -1px;
}
.content-side > .pull-r,
.content-side > .pull-x {
  margin-right: -1.125rem;
}
.content-side > .pull-r,
.content-side > .pull-x {
  margin-left: -1.125rem;
}
.content-side > .pull {
  margin: -1.125rem -1.125rem -1px;
}
.content-side.content-side-full {
  padding-bottom: 1.125rem;
}
.content-side.content-side-full > .pull-b,
.content-side.content-side-full > .pull-y,
.content-side.content-side-full > .pull {
  margin-bottom: -1.125rem;
}

.content-side p,
.content-side .push,
.content-side .block,
.content-side .items-push > div {
  margin-bottom: 1.125rem;
}
.content-side .items-push-2x > div {
  margin-bottom: 2.25rem;
}
.content-side .items-push-3x > div {
  margin-bottom: 3.375rem;
}

#page-header {
  position: relative;
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
}

.overlay-header {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transform: translateY(-100%);
  will-change: opacity, transform;
}
.overlay-header.show {
  opacity: 1;
  transform: translateY(0);
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 3.6rem;
}
#page-header .content-header {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}
@media (min-width: 768px) {
  #page-header .content-header {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}

#sidebar .content-header, #side-overlay .content-header {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.smini-visible,
.smini-visible-block {
  display: none;
}

.smini-show {
  opacity: 0;
}

.smini-show,
.smini-hide {
  transition: opacity 0.45s cubic-bezier(0.2, 0.61, 0.42, 0.97);
}

#sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1032;
  width: 100%;
  height: 100%;
  overflow-y: visible;
  background-color: #fff;
  transform: translateX(-100%) translateY(0) translateZ(0);
  -webkit-overflow-scrolling: touch;
  will-change: transform;
}
.side-trans-enabled #sidebar {
  transition: transform 0.45s cubic-bezier(0.2, 0.61, 0.42, 0.97);
}
.sidebar-r #sidebar {
  right: 0;
  left: auto;
  transform: translateX(100%) translateY(0) translateZ(0);
}
#sidebar .js-sidebar-scroll {
  height: calc(100% - 3.6rem);
  overflow-y: auto;
}
#sidebar .js-sidebar-scroll.full-height {
  height: 100%;
}
@media (max-width: 991.98px) {
  .sidebar-o-xs #sidebar {
    transform: translateX(0) translateY(0) translateZ(0);
  }
}
@media (min-width: 992px) {
  #sidebar {
    width: 250px;
  }
  .sidebar-o #sidebar {
    transform: translateX(0) translateY(0) translateZ(0);
  }
  .sidebar-mini.sidebar-o #sidebar {
    overflow-x: hidden;
    transform: translateX(-186px) translateY(0) translateZ(0);
    transition: transform 0.45s cubic-bezier(0.2, 0.61, 0.42, 0.97);
  }
  .sidebar-mini.sidebar-o.sidebar-r #sidebar {
    transform: translateX(186px) translateY(0) translateZ(0);
  }
  .sidebar-mini.sidebar-o #sidebar .content-side, .sidebar-mini.sidebar-o #sidebar .content-header {
    width: 250px;
    transform: translateX(186px) translateY(0) translateZ(0);
    transition: transform 0.45s cubic-bezier(0.2, 0.61, 0.42, 0.97);
    will-change: transform;
  }
  .sidebar-mini.sidebar-o #sidebar .content-header {
    position: relative;
    z-index: 1;
  }
  .sidebar-mini.sidebar-o #sidebar:hover, .sidebar-mini.sidebar-o #sidebar:hover .content-side, .sidebar-mini.sidebar-o.sidebar-r #sidebar .content-side, .sidebar-mini.sidebar-o #sidebar:hover .content-header, .sidebar-mini.sidebar-o.sidebar-r #sidebar .content-header {
    transform: translateX(0);
  }
  .sidebar-mini.sidebar-o #sidebar .nav-main,
.sidebar-mini.sidebar-o #sidebar .nav-main .nav-main-link-icon {
    transition: transform 0.45s cubic-bezier(0.2, 0.61, 0.42, 0.97);
  }
  .sidebar-mini.sidebar-o #sidebar .nav-main .nav-main-heading,
.sidebar-mini.sidebar-o #sidebar .nav-main .nav-main-link-name,
.sidebar-mini.sidebar-o #sidebar .nav-main .nav-main-link-badge {
    transition: opacity 0.45s cubic-bezier(0.2, 0.61, 0.42, 0.97);
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-hide {
    opacity: 0;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-show {
    opacity: 1;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-hidden {
    display: none;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-visible {
    display: inline-block;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-visible-block {
    display: block;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main {
    transform: translateX(-1.125rem);
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-link-icon {
    transform: translateX(0.75rem);
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-heading,
.sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-link-name,
.sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-link-badge {
    opacity: 0;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-link.active,
.sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-item.open > .nav-main-link-submenu {
    border-radius: 0;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main > .nav-main-item > .nav-main-submenu {
    display: none;
  }
}

#side-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1034;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  transform: translateX(100%) translateY(0) translateZ(0);
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  will-change: transform;
}
.side-trans-enabled #side-overlay {
  transition: transform 0.45s cubic-bezier(0.2, 0.61, 0.42, 0.97), opacity 0.45s cubic-bezier(0.2, 0.61, 0.42, 0.97);
}
.sidebar-r #side-overlay {
  right: auto;
  left: 0;
  transform: translateX(-100%) translateY(0) translateZ(0);
}
.side-overlay-o #side-overlay {
  transform: translateX(0) translateY(0) translateZ(0);
  opacity: 1;
}
@media (min-width: 992px) {
  #side-overlay {
    width: 320px;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
    transform: translateX(110%) translateY(0) translateZ(0);
  }
  .sidebar-r #side-overlay {
    transform: translateX(-110%) translateY(0) translateZ(0);
  }
  .side-overlay-hover #side-overlay {
    transform: translateX(305px) translateY(0) translateZ(0);
    opacity: 1;
  }
  .sidebar-r.side-overlay-hover #side-overlay {
    transform: translateX(-305px) translateY(0) translateZ(0);
    opacity: 1;
  }
  .side-overlay-hover #side-overlay:hover, .side-overlay-o #side-overlay, .side-overlay-o.side-overlay-hover #side-overlay {
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.15);
    transform: translateX(0) translateY(0) translateZ(0);
    opacity: 1;
  }
}

#page-container > #page-header .content-header,
#page-container > #page-header .content,
#page-container > #main-container .content,
#page-container > #page-footer .content {
  max-width: 1920px;
}
@media (min-width: 1200px) {
  #page-container.main-content-narrow > #page-header .content-header,
#page-container.main-content-narrow > #page-header .content,
#page-container.main-content-narrow > #main-container .content,
#page-container.main-content-narrow > #page-footer .content {
    width: 92%;
  }
}
#page-container.main-content-boxed > #page-header .content-header,
#page-container.main-content-boxed > #page-header .content,
#page-container.main-content-boxed > #main-container .content,
#page-container.main-content-boxed > #page-footer .content {
  max-width: 1200px;
}
#page-container.page-header-dark #page-header {
  color: #c3cde4;
  background-color: #dc5834;
}
#page-container.page-header-glass #page-header {
  position: absolute;
  background-color: transparent;
}
#page-container.page-header-glass.page-header-fixed #page-header {
  transition: background-color 0.2s linear;
  will-change: background-color;
  box-shadow: none;
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll #page-header {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(1rem);
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll.page-header-dark #page-header {
  background-color: rgba(220, 88, 52, 0.9);
  box-shadow: none;
  backdrop-filter: blur(1rem);
}
#page-container.page-header-glass #main-container, #page-container.page-header-glass.page-header-fixed #main-container {
  padding-top: 0;
}
#page-container.page-header-fixed #page-header {
  position: fixed;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.02);
}
#page-container.page-header-fixed.page-header-dark #page-header {
  box-shadow: none;
}
#page-container.page-header-fixed #main-container {
  padding-top: 3.6rem;
}
#page-container.page-header-fixed #page-header, #page-container.page-header-glass #page-header {
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  min-width: 320px;
  max-width: 100%;
  width: auto;
}
@media (min-width: 992px) {
  #page-container.page-header-fixed.sidebar-o #page-header, #page-container.page-header-glass.sidebar-o #page-header {
    padding-left: 250px;
  }
  #page-container.page-header-fixed.sidebar-o #page-header .overlay-header, #page-container.page-header-glass.sidebar-o #page-header .overlay-header {
    left: 250px;
  }
  #page-container.page-header-fixed.sidebar-r.sidebar-o #page-header, #page-container.page-header-glass.sidebar-r.sidebar-o #page-header {
    padding-right: 250px;
    padding-left: 0;
  }
  #page-container.page-header-fixed.sidebar-r.sidebar-o #page-header .overlay-header, #page-container.page-header-glass.sidebar-r.sidebar-o #page-header .overlay-header {
    right: 250px;
    left: 0;
  }
  #page-container.page-header-fixed.sidebar-mini.sidebar-o #page-header, #page-container.page-header-glass.sidebar-mini.sidebar-o #page-header {
    padding-left: 64px;
  }
  #page-container.page-header-fixed.sidebar-mini.sidebar-o #page-header .overlay-header, #page-container.page-header-glass.sidebar-mini.sidebar-o #page-header .overlay-header {
    left: 64px;
  }
  #page-container.page-header-fixed.sidebar-mini.sidebar-r.sidebar-o #page-header, #page-container.page-header-glass.sidebar-mini.sidebar-r.sidebar-o #page-header {
    padding-right: 64px;
    padding-left: 0;
  }
  #page-container.page-header-fixed.sidebar-mini.sidebar-r.sidebar-o #page-header .overlay-header, #page-container.page-header-glass.sidebar-mini.sidebar-r.sidebar-o #page-header .overlay-header {
    right: 64px;
    left: 0;
  }
}
#page-container.page-footer-fixed #page-footer {
  position: fixed;
  height: 3rem;
}
#page-container.page-footer-fixed #main-container {
  padding-bottom: 3rem;
}
#page-container.page-footer-fixed #page-footer {
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  min-width: 320px;
  max-width: 100%;
  width: auto;
}
@media (min-width: 992px) {
  #page-container.page-footer-fixed.sidebar-o #page-footer {
    padding-left: 250px;
  }
  #page-container.page-footer-fixed.sidebar-r.sidebar-o #page-footer {
    padding-right: 250px;
    padding-left: 0;
  }
}
#page-container.sidebar-dark #sidebar {
  color: #dfe4f1;
  background-color: #2a303c;
  box-shadow: none;
}
#page-container.side-scroll #sidebar .js-sidebar-scroll,
#page-container.side-scroll #side-overlay {
  overflow-y: visible;
}
@media (min-width: 992px) {
  #page-container.side-scroll #sidebar .content-header,
#page-container.side-scroll #sidebar .content-side {
    width: 250px !important;
  }
  #page-container.side-scroll #side-overlay .content-header,
#page-container.side-scroll #side-overlay .content-side {
    width: 320px !important;
  }
}
@media (min-width: 992px) {
  #page-container.sidebar-o {
    padding-left: 250px;
  }
  #page-container.sidebar-r.sidebar-o {
    padding-right: 250px;
    padding-left: 0;
  }
  #page-container.sidebar-mini.sidebar-o {
    padding-left: 64px;
  }
  #page-container.sidebar-mini.sidebar-o.sidebar-r {
    padding-right: 64px;
    padding-left: 0;
  }
}

.block {
  margin-bottom: 1.75rem;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(214, 221, 237, 0.5), 0 1px 2px rgba(214, 221, 237, 0.5);
}
.block .block, .content-side .block {
  box-shadow: none;
}

.block-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.25rem;
  transition: opacity 0.25s ease-out;
}
.block-header.block-header-rtl {
  flex-direction: row-reverse;
}
.block-header.block-header-rtl .block-title {
  text-align: right;
}
.block-header.block-header-rtl .block-options {
  padding-right: 1.25rem;
  padding-left: 0;
}
.block-header-default {
  background-color: #f8f9fc;
}

.block-title {
  flex: 1 1 auto;
  min-height: 1.75rem;
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75;
}
.block-title small, .block-title .small {
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #6c757d;
}

.block-content {
  transition: opacity 0.25s ease-out;
  width: 100%;
  margin: 0 auto;
  padding: 1.25rem 1.25rem 1px;
  overflow-x: visible;
}
.block-content > .pull-t,
.block-content > .pull-y {
  margin-top: -1.25rem;
}
.block-content > .pull-b,
.block-content > .pull-y {
  margin-bottom: -1px;
}
.block-content > .pull-r,
.block-content > .pull-x {
  margin-right: -1.25rem;
}
.block-content > .pull-r,
.block-content > .pull-x {
  margin-left: -1.25rem;
}
.block-content > .pull {
  margin: -1.25rem -1.25rem -1px;
}
.block-content.block-content-full {
  padding-bottom: 1.25rem;
}
.block-content.block-content-full > .pull-b,
.block-content.block-content-full > .pull-y,
.block-content.block-content-full > .pull {
  margin-bottom: -1.25rem;
}

.block-content p,
.block-content .push,
.block-content .block,
.block-content .items-push > div {
  margin-bottom: 1.25rem;
}
.block-content .items-push-2x > div {
  margin-bottom: 2.5rem;
}
.block-content .items-push-3x > div {
  margin-bottom: 3.75rem;
}
.block-content.block-content-sm {
  padding-top: 0.625rem;
}
.block-content.block-content-sm > .pull-t,
.block-content.block-content-sm > .pull-y,
.block-content.block-content-sm > .pull {
  margin-top: -0.625rem;
}
.block-content.block-content-sm.block-content-full {
  padding-bottom: 0.625rem;
}
.block-content.block-content-sm.block-content-full > .pull-b,
.block-content.block-content-sm.block-content-full > .pull-y,
.block-content.block-content-sm.block-content-full > .pull {
  margin-bottom: -0.625rem;
}

.block.block-bordered {
  border: 1px solid #dfe4f1;
  box-shadow: none;
}
.block.block-rounded {
  border-radius: 0.25rem;
}
.block.block-rounded > .block-header,
.block.block-rounded > .nav-tabs {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}
.block.block-rounded > .block-header:last-child,
.block.block-rounded > .nav-tabs:last-child {
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.block.block-rounded.block-mode-hidden > .block-header.block-header-default {
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.block.block-rounded > .block-content:first-child {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}
.block.block-rounded > .block-content:last-child {
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.block.block-rounded > .nav-tabs > .nav-item:first-child > .nav-link {
  border-top-left-radius: 0.2rem;
}
.block.block-rounded > .nav-tabs > .nav-item:last-child > .nav-link {
  border-top-right-radius: 0.2rem;
}
.block.block-themed > .block-header {
  border-bottom: none;
  color: #fff;
  background-color: #f4623a;
}
.block.block-themed > .block-header > .block-title {
  color: rgba(255, 255, 255, 0.9);
}
.block.block-themed > .block-header > .block-title small, .block.block-themed > .block-header > .block-title .small {
  color: rgba(255, 255, 255, 0.7);
}
.block.block-transparent {
  background-color: transparent;
  box-shadow: none;
}

.block.block-mode-hidden.block-bordered > .block-header {
  border-bottom: none;
}
.block.block-mode-hidden > .block-content {
  display: none;
}
.block.block-mode-loading {
  position: relative;
  overflow: hidden;
}
.block.block-mode-loading::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  z-index: 9;
  content: " ";
  background-color: rgba(255, 255, 255, 0.85);
}
.block.block-mode-loading::after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1.5rem 0 0 -1.5rem;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  color: #f4623a;
  font-family: Simple-Line-Icons;
  font-size: 1.125rem;
  text-align: center;
  z-index: 10;
  content: "\e09a";
  animation: fa-spin 1.75s infinite linear;
  border-radius: 1.5rem;
}
.block.block-mode-loading.block-mode-hidden::after {
  margin: -1rem 0 0 -1rem;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}
.block.block-mode-loading.block-mode-loading-dark::before {
  background-color: rgba(0, 0, 0, 0.85);
}
.block.block-mode-loading.block-mode-loading-dark::after {
  color: #fff;
}
.block.block-mode-loading.block-mode-loading-location::after {
  content: "\e06e";
}
.block.block-mode-loading.block-mode-loading-energy::after {
  content: "\e020";
}
.block.block-mode-loading.block-mode-loading-refresh::after {
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f021";
}
.block.block-mode-loading.block-mode-loading-sun::after {
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f185";
}
.block.block-mode-loading.block-mode-loading-repeat::after {
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f01e";
}
.block.block-mode-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1035;
  margin-bottom: 0;
  overflow-y: auto;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
}
.block.block-mode-fullscreen.block-bordered {
  border: none;
}
.block.block-mode-fullscreen.block-rounded {
  border-radius: 0;
}
.block.block-mode-fullscreen.block-transparent {
  background-color: #fff;
}
.block.block-mode-pinned {
  position: fixed;
  right: 0.75rem;
  bottom: 0;
  margin-bottom: 0 !important;
  width: 100%;
  max-width: 300px;
  z-index: 1031;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
}
.block.block-mode-pinned > .block-content {
  max-height: 250px;
  overflow-y: auto;
}
@media (min-width: 576px) {
  .block.block-mode-pinned {
    max-width: 400px;
  }
}

a.block {
  display: block;
  color: #343a40;
  font-weight: normal;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out, box-shadow 0.15s ease-out;
}
a.block:hover {
  color: #343a40;
  opacity: 0.65;
}
a.block:active {
  opacity: 1;
}
a.block.block-link-rotate:hover {
  transform: rotate(1deg);
  opacity: 1;
}
a.block.block-link-rotate:active {
  transform: rotate(0deg);
}
a.block.block-link-pop:hover {
  box-shadow: 0 0.5rem 2rem #d1d8ea;
  transform: translateY(-2px);
  opacity: 1;
}
a.block.block-link-pop:active {
  box-shadow: 0 0.25rem 0.75rem #e9edf5;
  transform: translateY(0);
}
a.block.block-link-shadow:hover {
  box-shadow: 0 0 2.25rem #d1d8ea;
  opacity: 1;
}
a.block.block-link-shadow:active {
  box-shadow: 0 0 1.125rem #dfe4f1;
}

.block.block-fx-shadow {
  box-shadow: 0 0 2.25rem #d1d8ea;
  opacity: 1;
}
.block.block-fx-pop {
  box-shadow: 0 0.5rem 2rem #d1d8ea;
  opacity: 1;
}
.block.block-fx-rotate-right {
  transform: rotate(1deg);
  opacity: 1;
}
.block.block-fx-rotate-left {
  transform: rotate(-1deg);
  opacity: 1;
}

.block-options {
  flex: 0 0 auto;
  padding-left: 1.25rem;
}
.block-options .block-options-item {
  padding: 0 0.25rem;
  line-height: 1.2;
}
.block.block-themed .block-options .block-options-item {
  color: #fff;
}
.block-options .block-options-item,
.block-options .dropdown {
  display: inline-block;
}

.block-sticky-options {
  position: relative;
}
.block-sticky-options .block-options {
  position: absolute;
  top: 14px;
  right: 1.25rem;
}
.block-sticky-options .block-options.block-options-left {
  right: auto;
  left: 1.25rem;
  padding-right: 10px;
  padding-left: 0;
}

.btn-block-option {
  display: inline-block;
  padding: 6px 5px;
  line-height: 1;
  color: #f4623a;
  background: none;
  border: none;
  cursor: pointer;
}
.btn-block-option.dropdown-toggle:after {
  position: relative;
  top: 2px;
}
.btn-block-option .si {
  position: relative;
  top: 1px;
}
.btn-block-option:hover {
  text-decoration: none;
  color: #f79175;
}
a.btn-block-option:focus,
.active > a.btn-block-option,
.show > button.btn-block-option {
  text-decoration: none;
  color: #f79175;
}

.btn-block-option:focus {
  outline: none !important;
  color: #f79175;
}
.btn-block-option:active {
  color: #fbc8ba;
}
.block.block-themed .btn-block-option {
  color: #fff;
  opacity: 0.7;
}
.block.block-themed .btn-block-option:hover {
  color: #fff;
  opacity: 1;
}
a.block.block-themed .btn-block-option:focus,
.active > a.block.block-themed .btn-block-option,
.show > button.block.block-themed .btn-block-option {
  color: #fff;
  opacity: 1;
}

.block.block-themed .btn-block-option:focus {
  color: #fff;
  opacity: 1;
}
.block.block-themed .btn-block-option:active {
  color: #fff;
  opacity: 0.6;
}

#page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f4623a;
  z-index: 999998;
  transform: translateY(-100%);
}
#page-loader.show {
  transform: translateY(0);
}
#page-loader::after, #page-loader::before {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  border-radius: 100%;
  content: "";
  z-index: 999999;
}
#page-loader::after {
  margin-top: -160px;
  margin-left: -160px;
  width: 320px;
  height: 320px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 4px solid rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.15);
  animation: page-loader-inner 1.25s infinite ease-out;
}
#page-loader::before {
  margin-top: -40px;
  margin-left: -40px;
  width: 80px;
  height: 80px;
  background-color: white;
  animation: page-loader-outer 1.25s infinite ease-in;
}

@keyframes page-loader-inner {
  0%, 40% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes page-loader-outer {
  0% {
    transform: scale(1);
  }
  40%, 100% {
    transform: scale(0);
    opacity: 0;
  }
}
.nav-main {
  padding-left: 0;
  list-style: none;
}

.nav-main-heading {
  padding-top: 1.5rem;
  padding-bottom: 0.2rem;
  padding-left: 0.625rem;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.075rem;
  color: #7a8793;
}

.nav-main-item {
  display: flex;
  flex-direction: column;
}

.nav-main-link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.625rem;
  margin: 1px 0;
  min-height: 2.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.0125em;
  color: #3f474e;
  border-radius: 0.2rem;
}
.nav-main-link .nav-main-link-icon {
  flex: 0 0 auto;
  display: inline-block;
  margin-right: 0.625rem;
  min-width: 1.25rem;
  font-size: 1rem;
  text-align: center;
  color: rgba(244, 98, 58, 0.7);
}
.nav-main-link .nav-main-link-name {
  flex: 1 1 auto;
  display: inline-block;
  max-width: 100%;
}
.nav-main-link .nav-main-link-badge {
  flex: 0 0 auto;
  display: inline-block;
  margin-left: 0.625rem;
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  font-size: 0.75rem;
  min-width: 1.5rem;
}
.nav-main-link:hover, .nav-main-link.active {
  color: #000;
  background-color: white;
}
.nav-main-link.nav-main-link-submenu {
  padding-right: 2rem;
}
.nav-main-link.nav-main-link-submenu::before, .nav-main-link.nav-main-link-submenu::after {
  position: absolute;
  top: 50%;
  right: 0.625rem;
  display: block;
  margin-top: -0.5rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  transition: opacity 0.3s cubic-bezier(0.2, 0.61, 0.42, 0.97), transform 0.3s cubic-bezier(0.2, 0.61, 0.42, 0.97);
}
.nav-main-link.nav-main-link-submenu::before {
  content: "\f107";
  opacity: 0.3;
}
.nav-main-link.nav-main-link-submenu::after {
  content: "\f106";
  opacity: 0;
  transform: translateY(-0.625rem);
}

.nav-main-submenu {
  padding-left: 2.5rem;
  list-style: none;
  height: 0;
  overflow: hidden;
  background-color: white;
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.nav-main-submenu .nav-main-item {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.2, 0.61, 0.42, 0.97), transform 0.3s cubic-bezier(0.2, 0.61, 0.42, 0.97);
  transform: translateX(1rem);
}
.nav-main-submenu .nav-main-heading {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}
.nav-main-submenu .nav-main-link {
  margin: 0;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0;
  font-size: 0.8125rem;
  min-height: 2rem;
  color: #626d78;
}
.nav-main-submenu .nav-main-link:hover, .nav-main-submenu .nav-main-link.active {
  color: #23272b;
  background-color: transparent;
}
.nav-main-submenu .nav-main-submenu {
  padding-left: 0.75rem;
}

.nav-main-item.open > .nav-main-link-submenu {
  color: #000;
  background-color: white;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.nav-main-item.open > .nav-main-link-submenu::before {
  opacity: 0;
  transform: translateY(0.625rem);
}
.nav-main-item.open > .nav-main-link-submenu::after {
  opacity: 0.6;
  transform: translateY(0);
}
.nav-main-item.open > .nav-main-submenu {
  height: auto;
  margin-top: -2px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.nav-main-item.open > .nav-main-submenu > .nav-main-item {
  opacity: 1;
  transform: translateX(0);
}

.nav-main-submenu .nav-main-item.open .nav-main-link {
  background-color: transparent;
}

@media (min-width: 992px) {
  .nav-main-horizontal {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .nav-main-horizontal .nav-main-heading {
    display: none;
  }
  .nav-main-horizontal > .nav-main-item {
    position: relative;
    display: inline-block;
  }
  .nav-main-horizontal > .nav-main-item:not(:last-child) {
    margin-right: 0.25rem;
  }
  .nav-main-horizontal .nav-main-submenu {
    position: absolute;
    left: 0;
    width: 220px;
    padding-left: 0;
    z-index: 995;
    box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.08);
  }
  .nav-main-horizontal .nav-main-submenu.nav-main-submenu-right {
    left: auto;
    right: 0;
  }
  .nav-main-horizontal .nav-main-submenu .nav-main-link.nav-main-link-submenu::before {
    content: "\f105";
  }
  .nav-main-horizontal .nav-main-submenu .nav-main-link.nav-main-link-submenu::after {
    content: "\f104";
  }
  .nav-main-horizontal .nav-main-submenu .nav-main-item {
    transform: translateY(-0.5rem);
  }
  .nav-main-horizontal .nav-main-submenu .nav-main-link {
    padding-left: 1rem;
  }
  .nav-main-horizontal .nav-main-item.open > .nav-main-submenu {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    overflow: visible;
  }
  .nav-main-horizontal .nav-main-item.open > .nav-main-submenu > .nav-main-item {
    transform: translateY(0);
  }
  .nav-main-horizontal .nav-main-submenu .nav-main-submenu {
    top: -0.5rem;
    left: auto;
    right: -100%;
    margin-top: 0;
  }
  .nav-main-horizontal.nav-main-horizontal-center {
    justify-content: center;
  }
  .nav-main-horizontal.nav-main-horizontal-justify > .nav-main-item {
    flex: 1 1 auto;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu {
    color: #000;
    background-color: white;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu::before {
    opacity: 0;
    transform: translateY(0.625rem);
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu::after {
    opacity: 0.6;
    transform: translateY(0);
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu {
    height: auto;
    margin-top: -2px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    overflow: visible;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu .nav-main-submenu {
    margin-top: 0;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu > .nav-main-item {
    opacity: 1;
    transform: translateY(0);
  }
}
.nav-main-dark .nav-main-heading,
.sidebar-dark #sidebar .nav-main-heading,
.page-header-dark #page-header .nav-main-heading,
.dark-mode #side-overlay .nav-main-heading,
.dark-mode #main-container .nav-main-heading {
  color: #697896;
}
.nav-main-dark .nav-main-link,
.sidebar-dark #sidebar .nav-main-link,
.page-header-dark #page-header .nav-main-link,
.dark-mode #side-overlay .nav-main-link,
.dark-mode #main-container .nav-main-link {
  color: #b4bccb;
}
.nav-main-dark .nav-main-link > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-link > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-link > .nav-main-link-icon,
.dark-mode #side-overlay .nav-main-link > .nav-main-link-icon,
.dark-mode #main-container .nav-main-link > .nav-main-link-icon {
  color: #546078;
}
.nav-main-dark .nav-main-link:hover, .nav-main-dark .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-link.active,
.page-header-dark #page-header .nav-main-link:hover,
.page-header-dark #page-header .nav-main-link.active,
.dark-mode #side-overlay .nav-main-link:hover,
.dark-mode #side-overlay .nav-main-link.active,
.dark-mode #main-container .nav-main-link:hover,
.dark-mode #main-container .nav-main-link.active {
  color: #fff;
  background-color: #21252f;
}
.nav-main-dark .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-submenu,
.page-header-dark #page-header .nav-main-submenu,
.dark-mode #side-overlay .nav-main-submenu,
.dark-mode #main-container .nav-main-submenu {
  background-color: #242933;
}
.nav-main-dark .nav-main-submenu .nav-main-link,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link,
.page-header-dark #page-header .nav-main-submenu .nav-main-link,
.dark-mode #side-overlay .nav-main-submenu .nav-main-link,
.dark-mode #main-container .nav-main-submenu .nav-main-link {
  color: #96a1b6;
}
.nav-main-dark .nav-main-submenu .nav-main-link:hover, .nav-main-dark .nav-main-submenu .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link.active,
.page-header-dark #page-header .nav-main-submenu .nav-main-link:hover,
.page-header-dark #page-header .nav-main-submenu .nav-main-link.active,
.dark-mode #side-overlay .nav-main-submenu .nav-main-link:hover,
.dark-mode #side-overlay .nav-main-submenu .nav-main-link.active,
.dark-mode #main-container .nav-main-submenu .nav-main-link:hover,
.dark-mode #main-container .nav-main-submenu .nav-main-link.active {
  color: #fff;
  background-color: transparent;
}
.nav-main-dark .nav-main-item.open > .nav-main-link-submenu,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-link-submenu,
.page-header-dark #page-header .nav-main-item.open > .nav-main-link-submenu,
.dark-mode #side-overlay .nav-main-item.open > .nav-main-link-submenu,
.dark-mode #main-container .nav-main-item.open > .nav-main-link-submenu {
  color: #fff;
  background-color: #21252f;
}
.nav-main-dark .nav-main-item.open > .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-submenu,
.page-header-dark #page-header .nav-main-item.open > .nav-main-submenu,
.dark-mode #side-overlay .nav-main-item.open > .nav-main-submenu,
.dark-mode #main-container .nav-main-item.open > .nav-main-submenu {
  background-color: #242933;
}
.nav-main-dark .nav-main-submenu .nav-main-item.open .nav-main-link,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-item.open .nav-main-link,
.page-header-dark #page-header .nav-main-submenu .nav-main-item.open .nav-main-link,
.dark-mode #side-overlay .nav-main-submenu .nav-main-item.open .nav-main-link,
.dark-mode #main-container .nav-main-submenu .nav-main-item.open .nav-main-link {
  background-color: transparent;
}

@media (min-width: 992px) {
  .nav-main-dark.nav-main-horizontal .nav-main-heading,
.sidebar-dark #sidebar .nav-main-horizontal .nav-main-heading,
.page-header-dark #page-header .nav-main-horizontal .nav-main-heading {
    color: rgba(255, 255, 255, 0.5);
  }
  .nav-main-dark.nav-main-horizontal .nav-main-link,
.sidebar-dark #sidebar .nav-main-horizontal .nav-main-link,
.page-header-dark #page-header .nav-main-horizontal .nav-main-link {
    color: rgba(255, 255, 255, 0.75);
  }
  .nav-main-dark.nav-main-horizontal .nav-main-link > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-horizontal .nav-main-link > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-horizontal .nav-main-link > .nav-main-link-icon {
    color: rgba(255, 255, 255, 0.4);
  }
  .nav-main-dark.nav-main-horizontal .nav-main-link:hover, .nav-main-dark.nav-main-horizontal .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-horizontal .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-horizontal .nav-main-link.active,
.page-header-dark #page-header .nav-main-horizontal .nav-main-link:hover,
.page-header-dark #page-header .nav-main-horizontal .nav-main-link.active {
    color: #fff;
    background-color: #20242d;
  }
  .nav-main-dark.nav-main-horizontal .nav-main-item.open > .nav-main-link-submenu,
.nav-main-dark.nav-main-horizontal .nav-main-item:hover > .nav-main-link-submenu,
.sidebar-dark #sidebar .nav-main-horizontal .nav-main-item.open > .nav-main-link-submenu,
.sidebar-dark #sidebar .nav-main-horizontal .nav-main-item:hover > .nav-main-link-submenu,
.page-header-dark #page-header .nav-main-horizontal .nav-main-item.open > .nav-main-link-submenu,
.page-header-dark #page-header .nav-main-horizontal .nav-main-item:hover > .nav-main-link-submenu {
    color: #fff;
    background-color: #20242d;
  }
  .nav-main-dark.nav-main-horizontal .nav-main-item.open > .nav-main-submenu,
.nav-main-dark.nav-main-horizontal .nav-main-item:hover > .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-horizontal .nav-main-item.open > .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-horizontal .nav-main-item:hover > .nav-main-submenu,
.page-header-dark #page-header .nav-main-horizontal .nav-main-item.open > .nav-main-submenu,
.page-header-dark #page-header .nav-main-horizontal .nav-main-item:hover > .nav-main-submenu {
    background-color: #20242d;
  }
  .nav-main-dark.nav-main-horizontal .nav-main-submenu .nav-main-item:hover .nav-main-link,
.sidebar-dark #sidebar .nav-main-horizontal .nav-main-submenu .nav-main-item:hover .nav-main-link,
.page-header-dark #page-header .nav-main-horizontal .nav-main-submenu .nav-main-item:hover .nav-main-link {
    background-color: transparent;
  }

  .page-header-dark #page-header .nav-main-horizontal .nav-main-link:hover, .page-header-dark #page-header .nav-main-horizontal .nav-main-link.active {
    background-color: #d24a24;
  }
  .page-header-dark #page-header .nav-main-horizontal .nav-main-item.open > .nav-main-link-submenu,
.page-header-dark #page-header .nav-main-horizontal .nav-main-item:hover > .nav-main-link-submenu {
    background-color: #d24a24;
  }
  .page-header-dark #page-header .nav-main-horizontal .nav-main-item.open > .nav-main-submenu,
.page-header-dark #page-header .nav-main-horizontal .nav-main-item:hover > .nav-main-submenu {
    background-color: #d24a24;
  }
  .page-header-dark #page-header .nav-main-horizontal .nav-main-submenu .nav-main-item:hover .nav-main-link {
    background-color: transparent;
  }
}
.list {
  padding-left: 0;
  list-style: none;
}
.list > li {
  position: relative;
}
.list.list-simple > li, .list-li-push > li {
  margin-bottom: 1.25rem;
}
.list.list-simple-mini > li, .list-li-push-sm > li {
  margin-bottom: 0.625rem;
}
.list-icons > li {
  margin-bottom: 1.25rem;
  padding-left: 0.5rem;
}

.list-activity > li {
  margin-bottom: 0.75rem;
  padding-bottom: 0.375rem;
  padding-left: 2rem;
}
.list-activity > li > i:first-child {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
}
.list-activity > li:not(:last-child) {
  border-bottom: 1px solid #edf0f7;
}

.list-events > li {
  margin-bottom: 0.25rem;
}
.list-events .js-event {
  background-color: #3c90df;
  transition: transform 0.3s ease-out;
}
.list-events .js-event:hover {
  cursor: move;
  transform: translateX(0.25rem);
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
}
a.item {
  will-change: opacity;
}
a.item:hover, a.item:focus {
  opacity: 0.6;
}
a.item:active {
  opacity: 1;
}
a.item.item-link-pop {
  will-change: transform;
}
a.item.item-link-pop:hover, a.item.item-link-pop:focus {
  opacity: 1;
  transform: scale(1.1);
}
a.item.item-link-pop:active {
  transform: scale(1);
}

.item.item-tiny {
  width: 1rem;
  height: 1rem;
}
.item.item-2x {
  width: 6rem;
  height: 6rem;
}
.item.item-3x {
  width: 8rem;
  height: 8rem;
}
.item.item-circle {
  border-radius: 50%;
}
.item.item-rounded {
  border-radius: 0.25rem;
}
.item.item-rounded-lg {
  border-radius: 1.75rem;
}
.item.item-rounded-lg.item-2x {
  border-radius: 2.25rem;
}
.item.item-rounded-lg.item-3x {
  border-radius: 3rem;
}

.overlay-container {
  position: relative;
}

.overlay-item {
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  line-height: 1;
}
.overlay-left .overlay-item {
  right: auto;
  left: 0;
}
.overlay-bottom .overlay-item {
  top: auto;
  bottom: 0;
}
.overlay-center .overlay-item {
  top: 50%;
  right: 50%;
  transform: translateX(50%) translateY(-50%);
}

.options-container {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: block;
}
.options-container .options-item {
  transition: transform 0.4s ease-out;
  will-change: transform;
}
.options-container .options-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: 2;
  content: "";
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s ease-in;
  will-change: opacity, transform;
}
.options-container .options-overlay-content {
  text-align: center;
}
.options-container:hover .options-overlay {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767.98px) {
  .options-container .options-overlay {
    display: none;
  }
  .options-container:hover .options-overlay {
    display: flex;
  }
}

.fx-item-zoom-in:hover .options-item {
  transform: scale(1.2, 1.2);
}

.fx-item-rotate-r:hover .options-item {
  transform: scale(1.4) rotate(8deg);
}

.fx-item-rotate-l:hover .options-item {
  transform: scale(1.4) rotate(-8deg);
}

.fx-overlay-slide-top .options-overlay {
  transform: translateY(100%);
}
.fx-overlay-slide-top:hover .options-overlay {
  transform: translateY(0);
}

.fx-overlay-slide-right .options-overlay {
  transform: translateX(-100%);
}
.fx-overlay-slide-right:hover .options-overlay {
  transform: translateX(0);
}

.fx-overlay-slide-down .options-overlay {
  transform: translateY(-100%);
}
.fx-overlay-slide-down:hover .options-overlay {
  transform: translateY(0);
}

.fx-overlay-slide-left .options-overlay {
  transform: translateX(100%);
}
.fx-overlay-slide-left:hover .options-overlay {
  transform: translateX(0);
}

.fx-overlay-zoom-in .options-overlay {
  transform: scale(0, 0);
}
.fx-overlay-zoom-in:hover .options-overlay {
  transform: scale(1, 1);
}

.fx-overlay-zoom-out .options-overlay {
  transform: scale(2, 2);
}
.fx-overlay-zoom-out:hover .options-overlay {
  transform: scale(1, 1);
}

.bg-black-5 {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.bg-black-10 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.bg-black-25 {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.bg-black-50 {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.bg-black-75 {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.bg-black-90 {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.bg-black-95 {
  background-color: rgba(0, 0, 0, 0.95) !important;
}

.bg-white-5 {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.bg-white-10 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.bg-white-25 {
  background-color: rgba(255, 255, 255, 0.25) !important;
}
.bg-white-50 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.bg-white-75 {
  background-color: rgba(255, 255, 255, 0.75) !important;
}
.bg-white-90 {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.bg-white-95 {
  background-color: rgba(255, 255, 255, 0.95) !important;
}

.bg-image {
  background-position: 0% 50%;
  background-size: cover;
}
.bg-image-top {
  background-position-y: 0%;
}
.bg-image-center {
  background-position-x: 50%;
}
.bg-image-bottom {
  background-position-y: 100%;
}
@media (min-width: 1200px) {
  .bg-image-fixed {
    background-attachment: fixed;
  }
}

.bg-pattern {
  background-repeat: repeat;
}

.bg-video {
  width: 100%;
  transform: translateZ(0);
}

.bg-primary-op {
  background-color: rgba(244, 98, 58, 0.75) !important;
}

a.bg-primary-op:hover, a.bg-primary-op:focus,
button.bg-primary-op:hover,
button.bg-primary-op:focus {
  background-color: rgba(238, 62, 13, 0.75) !important;
}

.bg-primary {
  background-color: #f4623a !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ee3e0d !important;
}

.bg-primary-dark {
  background-color: #ab4529 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #82341f !important;
}

.bg-primary-dark-op {
  background-color: rgba(171, 69, 41, 0.8) !important;
}

a.bg-primary-dark-op:hover, a.bg-primary-dark-op:focus,
button.bg-primary-dark-op:hover,
button.bg-primary-dark-op:focus {
  background-color: rgba(130, 52, 31, 0.8) !important;
}

.bg-primary-darker {
  background-color: #622717 !important;
}

a.bg-primary-darker:hover, a.bg-primary-darker:focus,
button.bg-primary-darker:hover,
button.bg-primary-darker:focus {
  background-color: #39170d !important;
}

.bg-primary-light {
  background-color: #f79175 !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #f46b45 !important;
}

.bg-primary-lighter {
  background-color: #fbc8ba !important;
}

a.bg-primary-lighter:hover, a.bg-primary-lighter:focus,
button.bg-primary-lighter:hover,
button.bg-primary-lighter:focus {
  background-color: #f8a28a !important;
}

.bg-success {
  background-color: #198754 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #115c39 !important;
}

.bg-success-light {
  background-color: #ddede5 !important;
}

a.bg-success-light:hover, a.bg-success-light:focus,
button.bg-success-light:hover,
button.bg-success-light:focus {
  background-color: #bcdbcc !important;
}

.bg-warning {
  background-color: #e69f17 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #b87f12 !important;
}

.bg-warning-light {
  background-color: #fbf1dc !important;
}

a.bg-warning-light:hover, a.bg-warning-light:focus,
button.bg-warning-light:hover,
button.bg-warning-light:focus {
  background-color: #f6dfae !important;
}

.bg-info {
  background-color: #3c90df !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #2177c7 !important;
}

.bg-info-light {
  background-color: #e2eefa !important;
}

a.bg-info-light:hover, a.bg-info-light:focus,
button.bg-info-light:hover,
button.bg-info-light:focus {
  background-color: #b7d5f3 !important;
}

.bg-danger {
  background-color: #f1373b !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #e51015 !important;
}

.bg-danger-light {
  background-color: #fde1e2 !important;
}

a.bg-danger-light:hover, a.bg-danger-light:focus,
button.bg-danger-light:hover,
button.bg-danger-light:focus {
  background-color: #fab1b4 !important;
}

.bg-success-op {
  background-color: rgba(221, 237, 229, 0.95) !important;
}

a.bg-success-op:hover, a.bg-success-op:focus,
button.bg-success-op:hover,
button.bg-success-op:focus {
  background-color: rgba(188, 219, 204, 0.95) !important;
}

.bg-warning-op {
  background-color: rgba(251, 241, 220, 0.95) !important;
}

a.bg-warning-op:hover, a.bg-warning-op:focus,
button.bg-warning-op:hover,
button.bg-warning-op:focus {
  background-color: rgba(246, 223, 174, 0.95) !important;
}

.bg-info-op {
  background-color: rgba(226, 238, 250, 0.95) !important;
}

a.bg-info-op:hover, a.bg-info-op:focus,
button.bg-info-op:hover,
button.bg-info-op:focus {
  background-color: rgba(183, 213, 243, 0.95) !important;
}

.bg-danger-op {
  background-color: rgba(253, 225, 226, 0.95) !important;
}

a.bg-danger-op:hover, a.bg-danger-op:focus,
button.bg-danger-op:hover,
button.bg-danger-op:focus {
  background-color: rgba(250, 177, 180, 0.95) !important;
}

.bg-body {
  background-color: #edf0f7 !important;
}

a.bg-body:hover, a.bg-body:focus,
button.bg-body:hover,
button.bg-body:focus {
  background-color: #cad3e7 !important;
}

.bg-body-light {
  background-color: #f8f9fc !important;
}

a.bg-body-light:hover, a.bg-body-light:focus,
button.bg-body-light:hover,
button.bg-body-light:focus {
  background-color: #d4dbec !important;
}

.bg-body-dark {
  background-color: #dfe4f1 !important;
}

a.bg-body-dark:hover, a.bg-body-dark:focus,
button.bg-body-dark:hover,
button.bg-body-dark:focus {
  background-color: #bcc7e1 !important;
}

.bg-body-extra-light {
  background-color: #fff !important;
}

a.bg-body-extra-light:hover, a.bg-body-extra-light:focus,
button.bg-body-extra-light:hover,
button.bg-body-extra-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-muted {
  background-color: #6c757d !important;
}

a.bg-muted:hover, a.bg-muted:focus,
button.bg-muted:hover,
button.bg-muted:focus {
  background-color: #545b62 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-gray {
  background-color: #ced4da !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #b1bbc4 !important;
}

.bg-gray-dark {
  background-color: #6c757d !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #545b62 !important;
}

.bg-gray-darker {
  background-color: #343a40 !important;
}

a.bg-gray-darker:hover, a.bg-gray-darker:focus,
button.bg-gray-darker:hover,
button.bg-gray-darker:focus {
  background-color: #1d2124 !important;
}

.bg-gray-light {
  background-color: #e9ecef !important;
}

a.bg-gray-light:hover, a.bg-gray-light:focus,
button.bg-gray-light:hover,
button.bg-gray-light:focus {
  background-color: #cbd3da !important;
}

.bg-gray-lighter {
  background-color: #f8f9fa !important;
}

a.bg-gray-lighter:hover, a.bg-gray-lighter:focus,
button.bg-gray-lighter:hover,
button.bg-gray-lighter:focus {
  background-color: #dae0e5 !important;
}

.bg-header-light {
  background-color: #fff !important;
}

a.bg-header-light:hover, a.bg-header-light:focus,
button.bg-header-light:hover,
button.bg-header-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-header-dark {
  background-color: #dc5834 !important;
}

a.bg-header-dark:hover, a.bg-header-dark:focus,
button.bg-header-dark:hover,
button.bg-header-dark:focus {
  background-color: #bd4221 !important;
}

.bg-sidebar-light {
  background-color: #fff !important;
}

a.bg-sidebar-light:hover, a.bg-sidebar-light:focus,
button.bg-sidebar-light:hover,
button.bg-sidebar-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-sidebar-dark {
  background-color: #2a303c !important;
}

a.bg-sidebar-dark:hover, a.bg-sidebar-dark:focus,
button.bg-sidebar-dark:hover,
button.bg-sidebar-dark:focus {
  background-color: #15181e !important;
}

.bg-gd-primary {
  background: #f4623a linear-gradient(135deg, #f4623a 0%, #f89c82 100%) !important;
}

.bg-gd-dusk {
  background: #d262e3 linear-gradient(135deg, #d262e3 0%, #f4623a 100%) !important;
}

.bg-gd-fruit {
  background: #89216b linear-gradient(135deg, #89216b 0%, #da4453 100%) !important;
}

.bg-gd-aqua {
  background: #02aab0 linear-gradient(135deg, #02aab0 0%, #00cdac 100%) !important;
}

.bg-gd-sublime {
  background: #6a82fb linear-gradient(135deg, #6a82fb 0%, #fc5c7d 100%) !important;
}

.bg-gd-sea {
  background: #2b32b2 linear-gradient(135deg, #2b32b2 0%, #1488cc 100%) !important;
}

.bg-gd-leaf {
  background: #e69f17 linear-gradient(135deg, #e69f17 0%, #198754 100%) !important;
}

.bg-gd-lake {
  background: #00f260 linear-gradient(135deg, #00f260 0%, #0575e6 100%) !important;
}

.bg-gd-sun {
  background: #e65c00 linear-gradient(135deg, #e65c00 0%, #f9d423 100%) !important;
}

.bg-gd-dusk-op {
  background: rgba(210, 98, 227, 0.5) linear-gradient(135deg, rgba(210, 98, 227, 0.5) 0%, rgba(244, 98, 58, 0.5) 100%) !important;
}

.bg-gd-fruit-op {
  background: rgba(137, 33, 107, 0.5) linear-gradient(135deg, rgba(137, 33, 107, 0.5) 0%, rgba(218, 68, 83, 0.5) 100%) !important;
}

.bg-gd-aqua-op {
  background: rgba(2, 170, 176, 0.5) linear-gradient(135deg, rgba(2, 170, 176, 0.5) 0%, rgba(0, 205, 172, 0.5) 100%) !important;
}

.bg-gd-sublime-op {
  background: rgba(106, 130, 251, 0.5) linear-gradient(135deg, rgba(106, 130, 251, 0.5) 0%, rgba(252, 92, 125, 0.5) 100%) !important;
}

.bg-gd-sea-op {
  background: rgba(43, 50, 178, 0.5) linear-gradient(135deg, rgba(43, 50, 178, 0.5) 0%, rgba(20, 136, 204, 0.5) 100%) !important;
}

.bg-gd-leaf-op {
  background: rgba(230, 159, 23, 0.5) linear-gradient(135deg, rgba(230, 159, 23, 0.5) 0%, rgba(25, 135, 84, 0.5) 100%) !important;
}

.bg-gd-lake-op {
  background: rgba(0, 242, 96, 0.5) linear-gradient(135deg, rgba(0, 242, 96, 0.5) 0%, rgba(5, 117, 230, 0.5) 100%) !important;
}

.bg-gd-sun-op {
  background: rgba(230, 92, 0, 0.5) linear-gradient(135deg, rgba(230, 92, 0, 0.5) 0%, rgba(249, 212, 35, 0.5) 100%) !important;
}

.bg-gd-white-op-r,
.bg-gd-white-op-l,
.bg-gd-white-op-rl {
  background: rgba(255, 255, 255, 0.98);
}

@media (min-width: 768px) {
  .bg-gd-white-op-r {
    background: rgba(255, 255, 255, 0.3) 0% linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0% 0%, rgba(255, 255, 255, 0.98) 50% 100%) !important;
  }

  .bg-gd-white-op-l {
    background: rgba(255, 255, 255, 0.3) 0% linear-gradient(-90deg, rgba(255, 255, 255, 0.3) 0% 0%, rgba(255, 255, 255, 0.98) 50% 100%) !important;
  }

  .bg-gd-white-op-rl {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.97) 30%, rgba(255, 255, 255, 0.97) 70%, rgba(255, 255, 255, 0.75) 100%);
  }
}
.bg-default {
  background-color: #f4623a !important;
}

a.bg-default:hover, a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
  background-color: #ee3e0d !important;
}

.bg-default-op {
  background-color: rgba(244, 98, 58, 0.75) !important;
}

a.bg-default-op:hover, a.bg-default-op:focus,
button.bg-default-op:hover,
button.bg-default-op:focus {
  background-color: rgba(238, 62, 13, 0.75) !important;
}

.bg-default-dark {
  background-color: #ab4529 !important;
}

a.bg-default-dark:hover, a.bg-default-dark:focus,
button.bg-default-dark:hover,
button.bg-default-dark:focus {
  background-color: #82341f !important;
}

.bg-default-dark-op {
  background-color: rgba(171, 69, 41, 0.8) !important;
}

a.bg-default-dark-op:hover, a.bg-default-dark-op:focus,
button.bg-default-dark-op:hover,
button.bg-default-dark-op:focus {
  background-color: rgba(130, 52, 31, 0.8) !important;
}

.bg-default-darker {
  background-color: #622717 !important;
}

a.bg-default-darker:hover, a.bg-default-darker:focus,
button.bg-default-darker:hover,
button.bg-default-darker:focus {
  background-color: #39170d !important;
}

.bg-default-light {
  background-color: #f79175 !important;
}

a.bg-default-light:hover, a.bg-default-light:focus,
button.bg-default-light:hover,
button.bg-default-light:focus {
  background-color: #f46b45 !important;
}

.bg-default-lighter {
  background-color: #fbc8ba !important;
}

a.bg-default-lighter:hover, a.bg-default-lighter:focus,
button.bg-default-lighter:hover,
button.bg-default-lighter:focus {
  background-color: #f8a28a !important;
}

.bg-gd-default {
  background: #f4623a linear-gradient(135deg, #f4623a 0%, #f89c82 100%) !important;
}

.bg-xwork {
  background-color: #2d72d9 !important;
}

a.bg-xwork:hover, a.bg-xwork:focus,
button.bg-xwork:hover,
button.bg-xwork:focus {
  background-color: #205bb3 !important;
}

.bg-xwork-op {
  background-color: rgba(45, 114, 217, 0.75) !important;
}

a.bg-xwork-op:hover, a.bg-xwork-op:focus,
button.bg-xwork-op:hover,
button.bg-xwork-op:focus {
  background-color: rgba(32, 91, 179, 0.75) !important;
}

.bg-xwork-dark {
  background-color: #1d2124 !important;
}

a.bg-xwork-dark:hover, a.bg-xwork-dark:focus,
button.bg-xwork-dark:hover,
button.bg-xwork-dark:focus {
  background-color: #060708 !important;
}

.bg-xwork-dark-op {
  background-color: rgba(29, 33, 36, 0.8) !important;
}

a.bg-xwork-dark-op:hover, a.bg-xwork-dark-op:focus,
button.bg-xwork-dark-op:hover,
button.bg-xwork-dark-op:focus {
  background-color: rgba(6, 7, 8, 0.8) !important;
}

.bg-xwork-darker {
  background-color: #060708 !important;
}

a.bg-xwork-darker:hover, a.bg-xwork-darker:focus,
button.bg-xwork-darker:hover,
button.bg-xwork-darker:focus {
  background-color: black !important;
}

.bg-xwork-light {
  background-color: #f79175 !important;
}

a.bg-xwork-light:hover, a.bg-xwork-light:focus,
button.bg-xwork-light:hover,
button.bg-xwork-light:focus {
  background-color: #f46b45 !important;
}

.bg-xwork-lighter {
  background-color: #fbc8ba !important;
}

a.bg-xwork-lighter:hover, a.bg-xwork-lighter:focus,
button.bg-xwork-lighter:hover,
button.bg-xwork-lighter:focus {
  background-color: #f8a28a !important;
}

.bg-gd-xwork {
  background: #2d72d9 linear-gradient(135deg, #2d72d9 0%, #6e9de5 100%) !important;
}

.bg-xmodern {
  background-color: #45619d !important;
}

a.bg-xmodern:hover, a.bg-xmodern:focus,
button.bg-xmodern:hover,
button.bg-xmodern:focus {
  background-color: #354b7a !important;
}

.bg-xmodern-op {
  background-color: rgba(69, 97, 157, 0.75) !important;
}

a.bg-xmodern-op:hover, a.bg-xmodern-op:focus,
button.bg-xmodern-op:hover,
button.bg-xmodern-op:focus {
  background-color: rgba(53, 75, 122, 0.75) !important;
}

.bg-xmodern-dark {
  background-color: #354b7a !important;
}

a.bg-xmodern-dark:hover, a.bg-xmodern-dark:focus,
button.bg-xmodern-dark:hover,
button.bg-xmodern-dark:focus {
  background-color: #263556 !important;
}

.bg-xmodern-dark-op {
  background-color: rgba(53, 75, 122, 0.8) !important;
}

a.bg-xmodern-dark-op:hover, a.bg-xmodern-dark-op:focus,
button.bg-xmodern-dark-op:hover,
button.bg-xmodern-dark-op:focus {
  background-color: rgba(38, 53, 86, 0.8) !important;
}

.bg-xmodern-darker {
  background-color: #263556 !important;
}

a.bg-xmodern-darker:hover, a.bg-xmodern-darker:focus,
button.bg-xmodern-darker:hover,
button.bg-xmodern-darker:focus {
  background-color: #161f33 !important;
}

.bg-xmodern-light {
  background-color: #6f89c0 !important;
}

a.bg-xmodern-light:hover, a.bg-xmodern-light:focus,
button.bg-xmodern-light:hover,
button.bg-xmodern-light:focus {
  background-color: #4d6caf !important;
}

.bg-xmodern-lighter {
  background-color: #b6c3df !important;
}

a.bg-xmodern-lighter:hover, a.bg-xmodern-lighter:focus,
button.bg-xmodern-lighter:hover,
button.bg-xmodern-lighter:focus {
  background-color: #92a6cf !important;
}

.bg-gd-xmodern {
  background: #45619d linear-gradient(135deg, #45619d 0%, #6f89c0 100%) !important;
}

.bg-xeco {
  background-color: #308a5a !important;
}

a.bg-xeco:hover, a.bg-xeco:focus,
button.bg-xeco:hover,
button.bg-xeco:focus {
  background-color: #236441 !important;
}

.bg-xeco-op {
  background-color: rgba(48, 138, 90, 0.75) !important;
}

a.bg-xeco-op:hover, a.bg-xeco-op:focus,
button.bg-xeco-op:hover,
button.bg-xeco-op:focus {
  background-color: rgba(35, 100, 65, 0.75) !important;
}

.bg-xeco-dark {
  background-color: #236441 !important;
}

a.bg-xeco-dark:hover, a.bg-xeco-dark:focus,
button.bg-xeco-dark:hover,
button.bg-xeco-dark:focus {
  background-color: #163e29 !important;
}

.bg-xeco-dark-op {
  background-color: rgba(35, 100, 65, 0.8) !important;
}

a.bg-xeco-dark-op:hover, a.bg-xeco-dark-op:focus,
button.bg-xeco-dark-op:hover,
button.bg-xeco-dark-op:focus {
  background-color: rgba(22, 62, 41, 0.8) !important;
}

.bg-xeco-darker {
  background-color: #163e29 !important;
}

a.bg-xeco-darker:hover, a.bg-xeco-darker:focus,
button.bg-xeco-darker:hover,
button.bg-xeco-darker:focus {
  background-color: #091810 !important;
}

.bg-xeco-light {
  background-color: #47bf7f !important;
}

a.bg-xeco-light:hover, a.bg-xeco-light:focus,
button.bg-xeco-light:hover,
button.bg-xeco-light:focus {
  background-color: #379d66 !important;
}

.bg-xeco-lighter {
  background-color: #93d9b4 !important;
}

a.bg-xeco-lighter:hover, a.bg-xeco-lighter:focus,
button.bg-xeco-lighter:hover,
button.bg-xeco-lighter:focus {
  background-color: #6dcc9a !important;
}

.bg-gd-xeco {
  background: #308a5a linear-gradient(135deg, #308a5a 0%, #47bf7f 100%) !important;
}

.bg-xsmooth {
  background-color: #7351c0 !important;
}

a.bg-xsmooth:hover, a.bg-xsmooth:focus,
button.bg-xsmooth:hover,
button.bg-xsmooth:focus {
  background-color: #5b3ba3 !important;
}

.bg-xsmooth-op {
  background-color: rgba(115, 81, 192, 0.75) !important;
}

a.bg-xsmooth-op:hover, a.bg-xsmooth-op:focus,
button.bg-xsmooth-op:hover,
button.bg-xsmooth-op:focus {
  background-color: rgba(91, 59, 163, 0.75) !important;
}

.bg-xsmooth-dark {
  background-color: #5b3ba3 !important;
}

a.bg-xsmooth-dark:hover, a.bg-xsmooth-dark:focus,
button.bg-xsmooth-dark:hover,
button.bg-xsmooth-dark:focus {
  background-color: #462d7e !important;
}

.bg-xsmooth-dark-op {
  background-color: rgba(91, 59, 163, 0.8) !important;
}

a.bg-xsmooth-dark-op:hover, a.bg-xsmooth-dark-op:focus,
button.bg-xsmooth-dark-op:hover,
button.bg-xsmooth-dark-op:focus {
  background-color: rgba(70, 45, 126, 0.8) !important;
}

.bg-xsmooth-darker {
  background-color: #462d7e !important;
}

a.bg-xsmooth-darker:hover, a.bg-xsmooth-darker:focus,
button.bg-xsmooth-darker:hover,
button.bg-xsmooth-darker:focus {
  background-color: #312058 !important;
}

.bg-xsmooth-light {
  background-color: #a089d4 !important;
}

a.bg-xsmooth-light:hover, a.bg-xsmooth-light:focus,
button.bg-xsmooth-light:hover,
button.bg-xsmooth-light:focus {
  background-color: #8264c7 !important;
}

.bg-xsmooth-lighter {
  background-color: #cdc1e9 !important;
}

a.bg-xsmooth-lighter:hover, a.bg-xsmooth-lighter:focus,
button.bg-xsmooth-lighter:hover,
button.bg-xsmooth-lighter:focus {
  background-color: #af9cdb !important;
}

.bg-gd-xsmooth {
  background: #7351c0 linear-gradient(135deg, #7351c0 0%, #a089d4 100%) !important;
}

.bg-xinspire {
  background-color: #2a807f !important;
}

a.bg-xinspire:hover, a.bg-xinspire:focus,
button.bg-xinspire:hover,
button.bg-xinspire:focus {
  background-color: #1d5a59 !important;
}

.bg-xinspire-op {
  background-color: rgba(42, 128, 127, 0.75) !important;
}

a.bg-xinspire-op:hover, a.bg-xinspire-op:focus,
button.bg-xinspire-op:hover,
button.bg-xinspire-op:focus {
  background-color: rgba(29, 90, 89, 0.75) !important;
}

.bg-xinspire-dark {
  background-color: #216362 !important;
}

a.bg-xinspire-dark:hover, a.bg-xinspire-dark:focus,
button.bg-xinspire-dark:hover,
button.bg-xinspire-dark:focus {
  background-color: #143d3c !important;
}

.bg-xinspire-dark-op {
  background-color: rgba(33, 99, 98, 0.8) !important;
}

a.bg-xinspire-dark-op:hover, a.bg-xinspire-dark-op:focus,
button.bg-xinspire-dark-op:hover,
button.bg-xinspire-dark-op:focus {
  background-color: rgba(20, 61, 60, 0.8) !important;
}

.bg-xinspire-darker {
  background-color: #174646 !important;
}

a.bg-xinspire-darker:hover, a.bg-xinspire-darker:focus,
button.bg-xinspire-darker:hover,
button.bg-xinspire-darker:focus {
  background-color: #0b2020 !important;
}

.bg-xinspire-light {
  background-color: #3dbab8 !important;
}

a.bg-xinspire-light:hover, a.bg-xinspire-light:focus,
button.bg-xinspire-light:hover,
button.bg-xinspire-light:focus {
  background-color: #309392 !important;
}

.bg-xinspire-lighter {
  background-color: #8ddad9 !important;
}

a.bg-xinspire-lighter:hover, a.bg-xinspire-lighter:focus,
button.bg-xinspire-lighter:hover,
button.bg-xinspire-lighter:focus {
  background-color: #67cdcc !important;
}

.bg-gd-xinspire {
  background: #2a807f linear-gradient(135deg, #2a807f 0%, #3dbab8 100%) !important;
}

.bg-xdream {
  background-color: #4657a3 !important;
}

a.bg-xdream:hover, a.bg-xdream:focus,
button.bg-xdream:hover,
button.bg-xdream:focus {
  background-color: #37447f !important;
}

.bg-xdream-op {
  background-color: rgba(70, 87, 163, 0.75) !important;
}

a.bg-xdream-op:hover, a.bg-xdream-op:focus,
button.bg-xdream-op:hover,
button.bg-xdream-op:focus {
  background-color: rgba(55, 68, 127, 0.75) !important;
}

.bg-xdream-dark {
  background-color: #2d3757 !important;
}

a.bg-xdream-dark:hover, a.bg-xdream-dark:focus,
button.bg-xdream-dark:hover,
button.bg-xdream-dark:focus {
  background-color: #1c2236 !important;
}

.bg-xdream-dark-op {
  background-color: rgba(45, 55, 87, 0.8) !important;
}

a.bg-xdream-dark-op:hover, a.bg-xdream-dark-op:focus,
button.bg-xdream-dark-op:hover,
button.bg-xdream-dark-op:focus {
  background-color: rgba(28, 34, 54, 0.8) !important;
}

.bg-xdream-darker {
  background-color: #20273e !important;
}

a.bg-xdream-darker:hover, a.bg-xdream-darker:focus,
button.bg-xdream-darker:hover,
button.bg-xdream-darker:focus {
  background-color: #0f121c !important;
}

.bg-xdream-light {
  background-color: #96a1d2 !important;
}

a.bg-xdream-light:hover, a.bg-xdream-light:focus,
button.bg-xdream-light:hover,
button.bg-xdream-light:focus {
  background-color: #7381c3 !important;
}

.bg-xdream-lighter {
  background-color: #dee1f1 !important;
}

a.bg-xdream-lighter:hover, a.bg-xdream-lighter:focus,
button.bg-xdream-lighter:hover,
button.bg-xdream-lighter:focus {
  background-color: #bac1e1 !important;
}

.bg-gd-xdream {
  background: #4657a3 linear-gradient(135deg, #4657a3 0%, #7381c3 100%) !important;
}

.bg-xpro {
  background-color: #4954cb !important;
}

a.bg-xpro:hover, a.bg-xpro:focus,
button.bg-xpro:hover,
button.bg-xpro:focus {
  background-color: #323daf !important;
}

.bg-xpro-op {
  background-color: rgba(73, 84, 203, 0.75) !important;
}

a.bg-xpro-op:hover, a.bg-xpro-op:focus,
button.bg-xpro-op:hover,
button.bg-xpro-op:focus {
  background-color: rgba(50, 61, 175, 0.75) !important;
}

.bg-xpro-dark {
  background-color: #394263 !important;
}

a.bg-xpro-dark:hover, a.bg-xpro-dark:focus,
button.bg-xpro-dark:hover,
button.bg-xpro-dark:focus {
  background-color: #262c43 !important;
}

.bg-xpro-dark-op {
  background-color: rgba(57, 66, 99, 0.8) !important;
}

a.bg-xpro-dark-op:hover, a.bg-xpro-dark-op:focus,
button.bg-xpro-dark-op:hover,
button.bg-xpro-dark-op:focus {
  background-color: rgba(38, 44, 67, 0.8) !important;
}

.bg-xpro-darker {
  background-color: #282e45 !important;
}

a.bg-xpro-darker:hover, a.bg-xpro-darker:focus,
button.bg-xpro-darker:hover,
button.bg-xpro-darker:focus {
  background-color: #151825 !important;
}

.bg-xpro-light {
  background-color: #858cdc !important;
}

a.bg-xpro-light:hover, a.bg-xpro-light:focus,
button.bg-xpro-light:hover,
button.bg-xpro-light:focus {
  background-color: #5d67d1 !important;
}

.bg-xpro-lighter {
  background-color: #b0b5e8 !important;
}

a.bg-xpro-lighter:hover, a.bg-xpro-lighter:focus,
button.bg-xpro-lighter:hover,
button.bg-xpro-lighter:focus {
  background-color: #8890dd !important;
}

.bg-gd-xpro {
  background: #4954cb linear-gradient(135deg, #4954cb 0%, #858cdc 100%) !important;
}

.bg-xplay {
  background-color: #c74433 !important;
}

a.bg-xplay:hover, a.bg-xplay:focus,
button.bg-xplay:hover,
button.bg-xplay:focus {
  background-color: #9e3629 !important;
}

.bg-xplay-op {
  background-color: rgba(199, 68, 51, 0.75) !important;
}

a.bg-xplay-op:hover, a.bg-xplay-op:focus,
button.bg-xplay-op:hover,
button.bg-xplay-op:focus {
  background-color: rgba(158, 54, 41, 0.75) !important;
}

.bg-xplay-dark {
  background-color: #963327 !important;
}

a.bg-xplay-dark:hover, a.bg-xplay-dark:focus,
button.bg-xplay-dark:hover,
button.bg-xplay-dark:focus {
  background-color: #6e251c !important;
}

.bg-xplay-dark-op {
  background-color: rgba(150, 51, 39, 0.8) !important;
}

a.bg-xplay-dark-op:hover, a.bg-xplay-dark-op:focus,
button.bg-xplay-dark-op:hover,
button.bg-xplay-dark-op:focus {
  background-color: rgba(110, 37, 28, 0.8) !important;
}

.bg-xplay-darker {
  background-color: #66231a !important;
}

a.bg-xplay-darker:hover, a.bg-xplay-darker:focus,
button.bg-xplay-darker:hover,
button.bg-xplay-darker:focus {
  background-color: #3d1510 !important;
}

.bg-xplay-light {
  background-color: #d46759 !important;
}

a.bg-xplay-light:hover, a.bg-xplay-light:focus,
button.bg-xplay-light:hover,
button.bg-xplay-light:focus {
  background-color: #c74433 !important;
}

.bg-xplay-lighter {
  background-color: #e9b1aa !important;
}

a.bg-xplay-lighter:hover, a.bg-xplay-lighter:focus,
button.bg-xplay-lighter:hover,
button.bg-xplay-lighter:focus {
  background-color: #df8c81 !important;
}

.bg-gd-xplay {
  background: #c74433 linear-gradient(135deg, #c74433 0%, #da796d 100%) !important;
}

.border-white-op {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-black-op {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.overflow-y-auto {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 992px) {
  .h100-scroll {
    height: 100vh;
    overflow-y: auto;
  }
}
.no-transition {
  transition: none !important;
}

.click-ripple {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  transform: scale(0);
}
.click-ripple.animate {
  animation: click-ripple 0.6s ease-out;
}

@keyframes click-ripple {
  50% {
    opacity: 0.6;
    transform: scale(2);
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
}
.space-x-0 > * + * {
  margin-left: 0;
}

.space-x-1 > * + * {
  margin-left: 0.25rem;
}

.space-x-2 > * + * {
  margin-left: 0.5rem;
}

.space-x-3 > * + * {
  margin-left: 1rem;
}

.space-x-4 > * + * {
  margin-left: 1.5rem;
}

.space-x-5 > * + * {
  margin-left: 3rem;
}

.space-x-6 > * + * {
  margin-left: 4.5rem;
}

.space-x-7 > * + * {
  margin-left: 6rem;
}

.space-x-8 > * + * {
  margin-left: 9rem;
}

.space-x-9 > * + * {
  margin-left: 12rem;
}

.space-x-10 > * + * {
  margin-left: 18rem;
}

.space-y-0 > * + * {
  margin-top: 0;
}

.space-y-1 > * + * {
  margin-top: 0.25rem;
}

.space-y-2 > * + * {
  margin-top: 0.5rem;
}

.space-y-3 > * + * {
  margin-top: 1rem;
}

.space-y-4 > * + * {
  margin-top: 1.5rem;
}

.space-y-5 > * + * {
  margin-top: 3rem;
}

.space-y-6 > * + * {
  margin-top: 4.5rem;
}

.space-y-7 > * + * {
  margin-top: 6rem;
}

.space-y-8 > * + * {
  margin-top: 9rem;
}

.space-y-9 > * + * {
  margin-top: 12rem;
}

.space-y-10 > * + * {
  margin-top: 18rem;
}

.text-primary {
  color: #f4623a !important;
}

a.text-primary.link-fx::before {
  background-color: #f4623a !important;
}
a.text-primary:hover, a.text-primary:focus {
  color: #ee3e0d !important;
}

.text-primary-dark {
  color: #ab4529 !important;
}

a.text-primary-dark.link-fx::before {
  background-color: #ab4529 !important;
}
a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #82341f !important;
}

.text-primary-darker {
  color: #622717 !important;
}

a.text-primary-darker.link-fx::before {
  background-color: #622717 !important;
}
a.text-primary-darker:hover, a.text-primary-darker:focus {
  color: #39170d !important;
}

.text-primary-light {
  color: #f79175 !important;
}

a.text-primary-light.link-fx::before {
  background-color: #f79175 !important;
}
a.text-primary-light:hover, a.text-primary-light:focus {
  color: #f46b45 !important;
}

.text-primary-lighter {
  color: #fbc8ba !important;
}

a.text-primary-lighter.link-fx::before {
  background-color: #fbc8ba !important;
}
a.text-primary-lighter:hover, a.text-primary-lighter:focus {
  color: #f8a28a !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark.link-fx::before {
  background-color: #343a40 !important;
}
a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-success {
  color: #198754 !important;
}

a.text-success.link-fx::before {
  background-color: #198754 !important;
}
a.text-success:hover, a.text-success:focus {
  color: #115c39 !important;
}

.text-success-light {
  color: #ddede5 !important;
}

a.text-success-light.link-fx::before {
  background-color: #ddede5 !important;
}
a.text-success-light:hover, a.text-success-light:focus {
  color: #bcdbcc !important;
}

.text-warning {
  color: #e69f17 !important;
}

a.text-warning.link-fx::before {
  background-color: #e69f17 !important;
}
a.text-warning:hover, a.text-warning:focus {
  color: #b87f12 !important;
}

.text-warning-light {
  color: #fbf1dc !important;
}

a.text-warning-light.link-fx::before {
  background-color: #fbf1dc !important;
}
a.text-warning-light:hover, a.text-warning-light:focus {
  color: #f6dfae !important;
}

.text-info {
  color: #3c90df !important;
}

a.text-info.link-fx::before {
  background-color: #3c90df !important;
}
a.text-info:hover, a.text-info:focus {
  color: #2177c7 !important;
}

.text-info-light {
  color: #e2eefa !important;
}

a.text-info-light.link-fx::before {
  background-color: #e2eefa !important;
}
a.text-info-light:hover, a.text-info-light:focus {
  color: #b7d5f3 !important;
}

.text-danger {
  color: #f1373b !important;
}

a.text-danger.link-fx::before {
  background-color: #f1373b !important;
}
a.text-danger:hover, a.text-danger:focus {
  color: #e51015 !important;
}

.text-danger-light {
  color: #fde1e2 !important;
}

a.text-danger-light.link-fx::before {
  background-color: #fde1e2 !important;
}
a.text-danger-light:hover, a.text-danger-light:focus {
  color: #fab1b4 !important;
}

.text-body-bg {
  color: #edf0f7 !important;
}

a.text-body-bg.link-fx::before {
  background-color: #edf0f7 !important;
}
a.text-body-bg:hover, a.text-body-bg:focus {
  color: #cad3e7 !important;
}

.text-body-bg-dark {
  color: #dfe4f1 !important;
}

a.text-body-bg-dark.link-fx::before {
  background-color: #dfe4f1 !important;
}
a.text-body-bg-dark:hover, a.text-body-bg-dark:focus {
  color: #bcc7e1 !important;
}

.text-body-bg-light {
  color: #f8f9fc !important;
}

a.text-body-bg-light.link-fx::before {
  background-color: #f8f9fc !important;
}
a.text-body-bg-light:hover, a.text-body-bg-light:focus {
  color: #d4dbec !important;
}

.text-body-color {
  color: #343a40 !important;
}

a.text-body-color.link-fx::before {
  background-color: #343a40 !important;
}
a.text-body-color:hover, a.text-body-color:focus {
  color: #1d2124 !important;
}

.text-body-color-dark {
  color: #212529 !important;
}

a.text-body-color-dark.link-fx::before {
  background-color: #212529 !important;
}
a.text-body-color-dark:hover, a.text-body-color-dark:focus {
  color: #0a0c0d !important;
}

.text-body-color-light {
  color: #dfe4f1 !important;
}

a.text-body-color-light.link-fx::before {
  background-color: #dfe4f1 !important;
}
a.text-body-color-light:hover, a.text-body-color-light:focus {
  color: #bcc7e1 !important;
}

.text-muted {
  color: #6c757d !important;
}

a.text-muted.link-fx::before {
  background-color: #6c757d !important;
}
a.text-muted:hover, a.text-muted:focus {
  color: #545b62 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white.link-fx::before {
  background-color: #fff !important;
}
a.text-white:hover, a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-white-25 {
  color: rgba(255, 255, 255, 0.25) !important;
}

a.text-white-25.link-fx::before {
  background-color: rgba(255, 255, 255, 0.25) !important;
}
a.text-white-25:hover, a.text-white-25:focus {
  color: rgba(230, 230, 230, 0.25) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

a.text-white-50.link-fx::before {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
a.text-white-50:hover, a.text-white-50:focus {
  color: rgba(230, 230, 230, 0.5) !important;
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

a.text-white-75.link-fx::before {
  background-color: rgba(255, 255, 255, 0.75) !important;
}
a.text-white-75:hover, a.text-white-75:focus {
  color: rgba(230, 230, 230, 0.75) !important;
}

.text-black {
  color: #000 !important;
}

a.text-black.link-fx::before {
  background-color: #000 !important;
}
a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-black-25 {
  color: rgba(0, 0, 0, 0.25) !important;
}

a.text-black-25.link-fx::before {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
a.text-black-25:hover, a.text-black-25:focus {
  color: rgba(0, 0, 0, 0.25) !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

a.text-black-50.link-fx::before {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
a.text-black-50:hover, a.text-black-50:focus {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-black-75 {
  color: rgba(0, 0, 0, 0.75) !important;
}

a.text-black-75.link-fx::before {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
a.text-black-75:hover, a.text-black-75:focus {
  color: rgba(0, 0, 0, 0.75) !important;
}

.text-gray {
  color: #ced4da !important;
}

a.text-gray.link-fx::before {
  background-color: #ced4da !important;
}
a.text-gray:hover, a.text-gray:focus {
  color: #b1bbc4 !important;
}

.text-gray-dark {
  color: #6c757d !important;
}

a.text-gray-dark.link-fx::before {
  background-color: #6c757d !important;
}
a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: #545b62 !important;
}

.text-gray-darker {
  color: #343a40 !important;
}

a.text-gray-darker.link-fx::before {
  background-color: #343a40 !important;
}
a.text-gray-darker:hover, a.text-gray-darker:focus {
  color: #1d2124 !important;
}

.text-gray-light {
  color: #e9ecef !important;
}

a.text-gray-light.link-fx::before {
  background-color: #e9ecef !important;
}
a.text-gray-light:hover, a.text-gray-light:focus {
  color: #cbd3da !important;
}

.text-gray-lighter {
  color: #f8f9fa !important;
}

a.text-gray-lighter.link-fx::before {
  background-color: #f8f9fa !important;
}
a.text-gray-lighter:hover, a.text-gray-lighter:focus {
  color: #dae0e5 !important;
}

.text-dual {
  color: #ab4529 !important;
}

a.text-dual.link-fx::before {
  background-color: #ab4529 !important;
}
a.text-dual:hover, a.text-dual:focus {
  color: #82341f !important;
}

.page-header-dark #page-header .text-dual,
.sidebar-dark #sidebar .text-dual {
  color: #dfe4f1 !important;
}
.page-header-dark #page-header a.text-dual.link-fx::before,
.sidebar-dark #sidebar a.text-dual.link-fx::before {
  background-color: #dfe4f1 !important;
}
.page-header-dark #page-header a.text-dual:hover, .page-header-dark #page-header a.text-dual:focus,
.sidebar-dark #sidebar a.text-dual:hover,
.sidebar-dark #sidebar a.text-dual:focus {
  color: #bcc7e1 !important;
}

.text-default {
  color: #f4623a !important;
}

a.text-default.link-fx::before {
  background-color: #f4623a !important;
}
a.text-default:hover, a.text-default:focus {
  color: #ee3e0d !important;
}

.text-default-dark {
  color: #ab4529 !important;
}

a.text-default-dark.link-fx::before {
  background-color: #ab4529 !important;
}
a.text-default-dark:hover, a.text-default-dark:focus {
  color: #82341f !important;
}

.text-default-darker {
  color: #622717 !important;
}

a.text-default-darker.link-fx::before {
  background-color: #622717 !important;
}
a.text-default-darker:hover, a.text-default-darker:focus {
  color: #39170d !important;
}

.text-default-light {
  color: #f79175 !important;
}

a.text-default-light.link-fx::before {
  background-color: #f79175 !important;
}
a.text-default-light:hover, a.text-default-light:focus {
  color: #f46b45 !important;
}

.text-default-lighter {
  color: #fbc8ba !important;
}

a.text-default-lighter.link-fx::before {
  background-color: #fbc8ba !important;
}
a.text-default-lighter:hover, a.text-default-lighter:focus {
  color: #f8a28a !important;
}

.text-xwork {
  color: #2d72d9 !important;
}

a.text-xwork.link-fx::before {
  background-color: #2d72d9 !important;
}
a.text-xwork:hover, a.text-xwork:focus {
  color: #205bb3 !important;
}

.text-xwork-dark {
  color: #1d2124 !important;
}

a.text-xwork-dark.link-fx::before {
  background-color: #1d2124 !important;
}
a.text-xwork-dark:hover, a.text-xwork-dark:focus {
  color: #060708 !important;
}

.text-xwork-darker {
  color: #060708 !important;
}

a.text-xwork-darker.link-fx::before {
  background-color: #060708 !important;
}
a.text-xwork-darker:hover, a.text-xwork-darker:focus {
  color: black !important;
}

.text-xwork-light {
  color: #f79175 !important;
}

a.text-xwork-light.link-fx::before {
  background-color: #f79175 !important;
}
a.text-xwork-light:hover, a.text-xwork-light:focus {
  color: #f46b45 !important;
}

.text-xwork-lighter {
  color: #fbc8ba !important;
}

a.text-xwork-lighter.link-fx::before {
  background-color: #fbc8ba !important;
}
a.text-xwork-lighter:hover, a.text-xwork-lighter:focus {
  color: #f8a28a !important;
}

.text-xmodern {
  color: #45619d !important;
}

a.text-xmodern.link-fx::before {
  background-color: #45619d !important;
}
a.text-xmodern:hover, a.text-xmodern:focus {
  color: #354b7a !important;
}

.text-xmodern-dark {
  color: #354b7a !important;
}

a.text-xmodern-dark.link-fx::before {
  background-color: #354b7a !important;
}
a.text-xmodern-dark:hover, a.text-xmodern-dark:focus {
  color: #263556 !important;
}

.text-xmodern-darker {
  color: #263556 !important;
}

a.text-xmodern-darker.link-fx::before {
  background-color: #263556 !important;
}
a.text-xmodern-darker:hover, a.text-xmodern-darker:focus {
  color: #161f33 !important;
}

.text-xmodern-light {
  color: #6f89c0 !important;
}

a.text-xmodern-light.link-fx::before {
  background-color: #6f89c0 !important;
}
a.text-xmodern-light:hover, a.text-xmodern-light:focus {
  color: #4d6caf !important;
}

.text-xmodern-lighter {
  color: #b6c3df !important;
}

a.text-xmodern-lighter.link-fx::before {
  background-color: #b6c3df !important;
}
a.text-xmodern-lighter:hover, a.text-xmodern-lighter:focus {
  color: #92a6cf !important;
}

.text-xeco {
  color: #308a5a !important;
}

a.text-xeco.link-fx::before {
  background-color: #308a5a !important;
}
a.text-xeco:hover, a.text-xeco:focus {
  color: #236441 !important;
}

.text-xeco-dark {
  color: #236441 !important;
}

a.text-xeco-dark.link-fx::before {
  background-color: #236441 !important;
}
a.text-xeco-dark:hover, a.text-xeco-dark:focus {
  color: #163e29 !important;
}

.text-xeco-darker {
  color: #163e29 !important;
}

a.text-xeco-darker.link-fx::before {
  background-color: #163e29 !important;
}
a.text-xeco-darker:hover, a.text-xeco-darker:focus {
  color: #091810 !important;
}

.text-xeco-light {
  color: #47bf7f !important;
}

a.text-xeco-light.link-fx::before {
  background-color: #47bf7f !important;
}
a.text-xeco-light:hover, a.text-xeco-light:focus {
  color: #379d66 !important;
}

.text-xeco-lighter {
  color: #93d9b4 !important;
}

a.text-xeco-lighter.link-fx::before {
  background-color: #93d9b4 !important;
}
a.text-xeco-lighter:hover, a.text-xeco-lighter:focus {
  color: #6dcc9a !important;
}

.text-xsmooth {
  color: #7351c0 !important;
}

a.text-xsmooth.link-fx::before {
  background-color: #7351c0 !important;
}
a.text-xsmooth:hover, a.text-xsmooth:focus {
  color: #5b3ba3 !important;
}

.text-xsmooth-dark {
  color: #5b3ba3 !important;
}

a.text-xsmooth-dark.link-fx::before {
  background-color: #5b3ba3 !important;
}
a.text-xsmooth-dark:hover, a.text-xsmooth-dark:focus {
  color: #462d7e !important;
}

.text-xsmooth-darker {
  color: #462d7e !important;
}

a.text-xsmooth-darker.link-fx::before {
  background-color: #462d7e !important;
}
a.text-xsmooth-darker:hover, a.text-xsmooth-darker:focus {
  color: #312058 !important;
}

.text-xsmooth-light {
  color: #a089d4 !important;
}

a.text-xsmooth-light.link-fx::before {
  background-color: #a089d4 !important;
}
a.text-xsmooth-light:hover, a.text-xsmooth-light:focus {
  color: #8264c7 !important;
}

.text-xsmooth-lighter {
  color: #cdc1e9 !important;
}

a.text-xsmooth-lighter.link-fx::before {
  background-color: #cdc1e9 !important;
}
a.text-xsmooth-lighter:hover, a.text-xsmooth-lighter:focus {
  color: #af9cdb !important;
}

.text-xinspire {
  color: #2a807f !important;
}

a.text-xinspire.link-fx::before {
  background-color: #2a807f !important;
}
a.text-xinspire:hover, a.text-xinspire:focus {
  color: #1d5a59 !important;
}

.text-xinspire-dark {
  color: #216362 !important;
}

a.text-xinspire-dark.link-fx::before {
  background-color: #216362 !important;
}
a.text-xinspire-dark:hover, a.text-xinspire-dark:focus {
  color: #143d3c !important;
}

.text-xinspire-darker {
  color: #174646 !important;
}

a.text-xinspire-darker.link-fx::before {
  background-color: #174646 !important;
}
a.text-xinspire-darker:hover, a.text-xinspire-darker:focus {
  color: #0b2020 !important;
}

.text-xinspire-light {
  color: #3dbab8 !important;
}

a.text-xinspire-light.link-fx::before {
  background-color: #3dbab8 !important;
}
a.text-xinspire-light:hover, a.text-xinspire-light:focus {
  color: #309392 !important;
}

.text-xinspire-lighter {
  color: #8ddad9 !important;
}

a.text-xinspire-lighter.link-fx::before {
  background-color: #8ddad9 !important;
}
a.text-xinspire-lighter:hover, a.text-xinspire-lighter:focus {
  color: #67cdcc !important;
}

.text-xdream {
  color: #4657a3 !important;
}

a.text-xdream.link-fx::before {
  background-color: #4657a3 !important;
}
a.text-xdream:hover, a.text-xdream:focus {
  color: #37447f !important;
}

.text-xdream-dark {
  color: #2d3757 !important;
}

a.text-xdream-dark.link-fx::before {
  background-color: #2d3757 !important;
}
a.text-xdream-dark:hover, a.text-xdream-dark:focus {
  color: #1c2236 !important;
}

.text-xdream-darker {
  color: #20273e !important;
}

a.text-xdream-darker.link-fx::before {
  background-color: #20273e !important;
}
a.text-xdream-darker:hover, a.text-xdream-darker:focus {
  color: #0f121c !important;
}

.text-xdream-light {
  color: #96a1d2 !important;
}

a.text-xdream-light.link-fx::before {
  background-color: #96a1d2 !important;
}
a.text-xdream-light:hover, a.text-xdream-light:focus {
  color: #7381c3 !important;
}

.text-xdream-lighter {
  color: #dee1f1 !important;
}

a.text-xdream-lighter.link-fx::before {
  background-color: #dee1f1 !important;
}
a.text-xdream-lighter:hover, a.text-xdream-lighter:focus {
  color: #bac1e1 !important;
}

.text-xpro {
  color: #4954cb !important;
}

a.text-xpro.link-fx::before {
  background-color: #4954cb !important;
}
a.text-xpro:hover, a.text-xpro:focus {
  color: #323daf !important;
}

.text-xpro-dark {
  color: #394263 !important;
}

a.text-xpro-dark.link-fx::before {
  background-color: #394263 !important;
}
a.text-xpro-dark:hover, a.text-xpro-dark:focus {
  color: #262c43 !important;
}

.text-xpro-darker {
  color: #282e45 !important;
}

a.text-xpro-darker.link-fx::before {
  background-color: #282e45 !important;
}
a.text-xpro-darker:hover, a.text-xpro-darker:focus {
  color: #151825 !important;
}

.text-xpro-light {
  color: #858cdc !important;
}

a.text-xpro-light.link-fx::before {
  background-color: #858cdc !important;
}
a.text-xpro-light:hover, a.text-xpro-light:focus {
  color: #5d67d1 !important;
}

.text-xpro-lighter {
  color: #b0b5e8 !important;
}

a.text-xpro-lighter.link-fx::before {
  background-color: #b0b5e8 !important;
}
a.text-xpro-lighter:hover, a.text-xpro-lighter:focus {
  color: #8890dd !important;
}

.text-xplay {
  color: #c74433 !important;
}

a.text-xplay.link-fx::before {
  background-color: #c74433 !important;
}
a.text-xplay:hover, a.text-xplay:focus {
  color: #9e3629 !important;
}

.text-xplay-dark {
  color: #963327 !important;
}

a.text-xplay-dark.link-fx::before {
  background-color: #963327 !important;
}
a.text-xplay-dark:hover, a.text-xplay-dark:focus {
  color: #6e251c !important;
}

.text-xplay-darker {
  color: #66231a !important;
}

a.text-xplay-darker.link-fx::before {
  background-color: #66231a !important;
}
a.text-xplay-darker:hover, a.text-xplay-darker:focus {
  color: #3d1510 !important;
}

.text-xplay-light {
  color: #d46759 !important;
}

a.text-xplay-light.link-fx::before {
  background-color: #d46759 !important;
}
a.text-xplay-light:hover, a.text-xplay-light:focus {
  color: #c74433 !important;
}

.text-xplay-lighter {
  color: #e9b1aa !important;
}

a.text-xplay-lighter.link-fx::before {
  background-color: #e9b1aa !important;
}
a.text-xplay-lighter:hover, a.text-xplay-lighter:focus {
  color: #df8c81 !important;
}

.animated {
  animation-duration: 1.2s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.bounce {
  animation-duration: 1.5s;
}

.animated.bounceIn,
.animated.bounceOut {
  animation-duration: 0.75s;
}

.animated.flipOutX,
.animated.flipOutY {
  animation-duration: 0.75s;
}

@keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
.flash {
  animation-name: flash;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  animation-name: pulse;
}

@keyframes rubberBand {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.rubberBand {
  animation-name: rubberBand;
}

@keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  animation-name: shake;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.swing {
  transform-origin: top center;
  animation-name: swing;
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.tada {
  animation-name: tada;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes wobble {
  0% {
    transform: none;
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    transform: none;
  }
}
.wobble {
  animation-name: wobble;
}

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  100% {
    transform: none;
  }
}
.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.bounceOut {
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
.bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
.bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}
.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes flip {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out;
  }
  40% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in;
  }
  80% {
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: perspective(400px);
    animation-timing-function: ease-in;
  }
}
.animated.flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
.flipInX {
  backface-visibility: visible !important;
  animation-name: flipInX;
}

@keyframes flipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
.flipInY {
  backface-visibility: visible !important;
  animation-name: flipInY;
}

@keyframes flipOutX {
  0% {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.flipOutX {
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  0% {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
.flipOutY {
  backface-visibility: visible !important;
  animation-name: flipOutY;
}

@keyframes lightSpeedIn {
  0% {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    transform: skewX(-5deg);
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}
.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  0% {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  100% {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
}
.rotateIn {
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  0% {
    transform-origin: center;
    opacity: 1;
  }
  100% {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
.rotateOut {
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  animation-name: hinge;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
  }
}
.rollIn {
  animation-name: rollIn;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
.rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
.zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutDown {
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform-origin: left center;
  }
}
.zoomOutLeft {
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(0.1) translate3d(2000px, 0, 0);
    transform-origin: right center;
  }
}
.zoomOutRight {
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutUp {
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  0% {
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}
.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}
.slideInLeft {
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}
.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  0% {
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}
.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  0% {
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    transform: translateY(100%);
  }
}
.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    transform: translateX(-100%);
  }
}
.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    transform: translateX(100%);
  }
}
.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    transform: translateY(-100%);
  }
}
.slideOutUp {
  animation-name: slideOutUp;
}

/* @import 'vendor/fontawesome/fontawesome';
@import 'vendor/fontawesome/regular';
@import 'vendor/fontawesome/solid';
@import 'vendor/fontawesome/brands'; */
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-scrollbar {
  left: auto;
  right: 2px;
  width: 5px;
}

.simplebar-scrollbar::before {
  left: 0;
  right: 0;
  background: rgba(57, 23, 13, 0.75);
}
.sidebar-dark #sidebar .simplebar-scrollbar::before {
  background: #fff;
}

/*@import 'vendor/bootstrap-colorpicker';
@import 'vendor/bootstrap-datepicker';
@import 'vendor/ckeditor';
@import 'vendor/dropzone';
@import 'vendor/datatables';
@import 'vendor/easy-pie-chart';
@import 'vendor/fullcalendar';
@import 'vendor/ion-range-slider';
@import 'vendor/jquery-sparkline';
@import 'vendor/jvector-map';
@import 'vendor/pw-strength';
@import 'vendor/select2';
@import 'vendor/simplemde';
@import 'vendor/slick';
@import 'vendor/nestable2';
@import 'vendor/flatpickr';

// RTL Support
@import 'dashmix/rtl-support';

// Dark mode
@import 'dashmix/dark-mode';*/
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}
.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}
.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}
.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--group {
  padding: 0;
}
.select2-container--default .select2-results__option--disabled {
  color: #999;
}
.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--default {
  /* Floating */
}
.select2-container--default .select2-selection--single {
  min-height: 2.375rem;
  border-color: #dfe4f1;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 0 1.25rem 0 0;
  line-height: 1.5rem;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
}
.select2-container--default .select2-selection--multiple {
  border-color: #dfe4f1;
  min-height: 2.375rem;
  padding: calc(0.375rem - 5px) 0.75rem 0.375rem 0.75rem;
}
.select2-container--default .select2-selection--multiple .select2-search__field {
  font-family: var(--bs-body-font-family);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-radius: 0.125rem;
  border: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice:first-child {
  margin-left: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  right: 0;
  left: auto;
  border-right: none;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #f4623a;
}
.form-floating .select2-container--default .select2-selection--single {
  min-height: 3.25rem;
  padding-top: 1.5rem;
}
.form-floating .select2-container--default .select2-selection--multiple {
  min-height: 3.25rem;
  padding-top: calc(1.5rem - 5px);
  padding-bottom: calc(0.25rem - 2px);
  line-height: 1.4rem;
}
.form-floating .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  font-size: 0.9rem;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  font-family: var(--bs-body-font-family) !important;
  border-color: #dfe4f1;
}
.select2-container--default .select2-dropdown {
  border-color: #dfe4f1;
  z-index: 1080;
}

.dropl .dropl-item.active ~ .dropl-off {
  display: none;
}

.drop-item-spin.active ~ .drop-item-spin-off {
  display: none;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.table > :not(caption) > * > * {
  padding: 0.1rem 0.3rem !important;
}

.snackbar {
  z-index: 1060;
  margin: 8px;
  display: none;
  position: fixed;
  right: 0;
  bottom: 2rem;
  left: 0;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.snackbar .snb-surface {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  min-width: 320px;
  max-width: 672px;
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.snackbar .snb-label {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 12px 14px;
}
.snackbar .snb-close {
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 50%;
  outline: none;
  margin-right: 8px;
  background-color: transparent;
  text-decoration: none;
}
.snackbar .snb-close:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.snackbar .snb-close span {
  line-height: 0;
  font-size: 1.5rem;
  opacity: 0.7;
}
.snackbar .snb-close span:hover {
  opacity: 1;
}
.snackbar.open {
  display: -ms-flexbox;
  display: flex;
}
.snackbar.open .snb-surface {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 0.15s cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 0.15s cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: opacity 0.15s cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 0.15s cubic-bezier(0, 0, 0.2, 1) 0ms;
  -o-transition: opacity 0.15s 0ms cubic-bezier(0, 0, 0.2, 1), transform 0.15s 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.15s cubic-bezier(0, 0, 0.2, 1) 0ms, transform 0.15s cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: opacity 0.15s cubic-bezier(0, 0, 0.2, 1) 0ms, transform 0.15s cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 0.15s cubic-bezier(0, 0, 0.2, 1) 0ms;
  opacity: 1;
  pointer-events: auto;
}

body {
  font-family: "Source Sans Pro";
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.form-control, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple,
.form-select {
  box-shadow: inset 0 0 0.5rem #ebeef4;
  border-radius: 0.125rem;
}
.form-control:focus, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--multiple:focus,
.form-select:focus {
  box-shadow: none;
  border-color: #f4623a;
}

.form-floating > .form-control:focus ~ label, .select2-container--default .form-floating > .select2-selection--single:focus ~ label, .select2-container--default .form-floating > .select2-selection--multiple:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.select2-container--default .form-floating > .select2-selection--single:not(:placeholder-shown) ~ label,
.select2-container--default .form-floating > .select2-selection--multiple:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.85;
  transform: scale(0.8) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > label {
  color: #495057;
}

.form-control ~ .btn-end, .select2-container--default .select2-selection--single ~ .btn-end, .select2-container--default .select2-selection--multiple ~ .btn-end {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  transition: padding 0.1s ease-in-out;
}

.form-floating .btn-end {
  position: absolute;
  height: 100%;
  top: 0;
  transition: padding 0.1s ease-in-out;
}
.form-floating > .form-control:focus ~ .btn-end, .select2-container--default .form-floating > .select2-selection--single:focus ~ .btn-end, .select2-container--default .form-floating > .select2-selection--multiple:focus ~ .btn-end, .form-floating > .form-control:not(:placeholder-shown) ~ .btn-end, .select2-container--default .form-floating > .select2-selection--single:not(:placeholder-shown) ~ .btn-end, .select2-container--default .form-floating > .select2-selection--multiple:not(:placeholder-shown) ~ .btn-end {
  padding-top: 1.5rem;
  padding-bottom: 0.25rem;
}

.v-container {
  border-radius: 0.125rem;
}
.v-container.is-invalid, .v-container.is-valid {
  padding: 0.0625rem;
  color: #fff;
}
.v-container.is-invalid {
  background-color: #c43256;
}
.v-container.is-valid {
  background-color: #198754;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02) !important;
}

.fw-600 {
  font-weight: 600;
}

.fshadow-0:focus {
  box-shadow: none;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
               supported by Chrome, Edge, Opera and Firefox */
}

.mw-35 {
  max-width: 35rem;
}

.mw-40 {
  max-width: 40rem;
}

.mw-50 {
  max-width: 50rem;
}

@media (max-width: 575.98px) {
  .w-sm-100 {
    width: 100% !important;
  }
}
.spinner-border-tinny {
  width: 0.8rem;
  height: 0.8rem;
  border-width: 0.2em;
}